import { Table, Modal, Space, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { useGetAllSuggesionBookQuery } from "../../../RTKQuery/Books";
const SuggestBook = () => {
  const [viewModal, setViewModal] = useState(false);
  const [SuggestedBookData, setSuggestedBookData] = useState();
  const [viewSuggesionBookData, setViewSuggetionBookData] = useState([]);
  const { data, error, isLoading } = useGetAllSuggesionBookQuery();
  useEffect(() => {
    setSuggestedBookData(data?.data);
  }, [data]);

  const handleViewSuggestedBooks = (row) => {
    setViewModal(true);
    setViewSuggetionBookData(row);
  };
  const handleCancel = () => {
    setViewModal(false);
  };

  const columns = [
    {
      title: "Book Name",
      dataIndex: "bookName",
      key: "nambookNamee",
      width: 200,
      align: "center",
      render: (_, book) => {
        return book?.bookName;
      },
      sorter: (a, b) => a.book.bookName.localeCompare(b.book.bookName),
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      align: "center",
      sorter: (a, b) => a.userType.localeCompare(b.userType),
    },
    {
      title: "Author Name",
      dataIndex: "authorName",
      key: "authorName",
      align: "center",
      sorter: (a, b) => a.authorName.localeCompare(b.authorName),
    },

    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "center",
      render: (text, row) => (
        <Space>
          <EyeOutlined onClick={() => handleViewSuggestedBooks(row)} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={SuggestedBookData}
        columns={columns}
        showSorterTooltip={false}
      />
      <Modal
        open={viewModal}
        title="Rented Books Details"
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="modal-text" span={9}>
            Book Name
          </Col>
          <Col className="modal-text" span={15}>
            {viewSuggesionBookData?.bookName}
          </Col>
          <Col className="modal-text" span={9}>
            Aauthor Name
          </Col>
          <Col className="modal-text" span={15}>
            {viewSuggesionBookData?.authorName}
          </Col>
          <Col className="modal-text" span={9}>
            user Name
          </Col>
          <Col className="modal-text" span={15}>
            {viewSuggesionBookData?.userName}
          </Col>
          <Col className="modal-text" span={9}>
            User Type
          </Col>
          <Col className="modal-text" span={15}>
            {viewSuggesionBookData?.userType}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SuggestBook;
