import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Select,
  Modal,
  Row,
  Col,
  Upload,
  Space,
  notification,
} from "antd";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import { BiRupee } from "react-icons/bi";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
import {
  LikeTwoTone,
  UploadOutlined,
  DislikeOutlined,
  PlusOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useGetAllCategoryQuery } from "../../../RTKQuery/Category";
import {
  useCreateAuthorsMutation,
  useCreateBooksMutation,
  useEditBooksMutation,
} from "../../../RTKQuery/FileHandling";
import {
  useGetAllAuthorByNameQuery,
  useGetAllAuthorsQuery,
} from "../../../RTKQuery/Author";
const CreateBook = ({
  isOpen,
  setIsOpen,
  editBookData,
  setEditBookData,
  setReloadedData,
  refechBooks,
}) => {
  const [bookForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [createBooks, { error: createBookError }] = useCreateBooksMutation();
  const [editBooks, { error: editBookError }] = useEditBooksMutation();
  const [authorForm] = Form.useForm();
  const { data: categoryData, refetch: categoryRefetch } =
    useGetAllCategoryQuery();
  const { data: authorsData, refetch: refetchAuthor } = useGetAllAuthorsQuery();
  const [openCreateAuthorModal, setOpenCreateAuthorModal] = useState(false);
  const [createAuthors, { error: createAuthorError }] =
    useCreateAuthorsMutation();
  const { data: filteredAuthorData } = useGetAllAuthorByNameQuery();
  const { Option } = Select;

  const handleCancel = () => {
    setIsOpen(false);
    setEditBookData(null);
    bookForm.resetFields();
  };
  const handleopenCreateAuthorModal = () => {
    setOpenCreateAuthorModal(true);
  };

  useEffect(() => {
    if (editBookData?.id) {
      const authorIds = editBookData?.authorIds;
      // const authorNames = Array.isArray(authorIds)
      //   ? authorIds.map((authorId) => {
      //       const author = authorsData?.data?.authors.find(
      //         (author) => author.id == authorId
      //       );
      //       return author ? author?.name : "";
      //     })
      //   : [];
      // const categoryName = categoryData?.data?.categories?.find((category) => {
      //   return category.id == editBookData?.categoryId;
      // })?.categoryName;
      const categoryName = editBookData?.categoryId;
      bookForm.setFieldsValue({
        id: editBookData?.id,
        bookRate: editBookData?.bookRate,
        categoryId: categoryName,
        authorIds: authorIds,
        bookName: editBookData?.bookName,
        numbersOfBooks: editBookData?.numbersOfBooks,
        bookSelfNumbers: editBookData?.bookSelfNumbers,
        ISBN: editBookData?.isbn,
        bookDescription: editBookData?.bookDescription,
        numberOfRatings: editBookData?.numberOfRatings,
        publisher: editBookData?.publisher,
      });
    } else {
      bookForm.resetFields();
    }
  }, [editBookData, bookForm, categoryData, filteredAuthorData]);

  const onFinish = (values) => {
    const id = editBookData?.id;
    const formData = new FormData();
    formData.append("bookRate", parseInt(values.bookRate));
    formData.append("categoryId", parseInt(values.categoryId));
    formData.append(
      "authorIds",
      values.authorIds.map((authorId) => parseInt(authorId))
    );
    formData.append("bookName", values.bookName);
    formData.append("publisher", values.publisher);
    formData.append("bookDescription", values.bookDescription);
    formData.append("numberOfRatings", parseFloat(values.numberOfRatings));
    formData.append("numbersOfBooks", parseInt(values.numbersOfBooks));
    formData.append("bookSelfNumbers", parseInt(values.bookSelfNumbers));
    formData.append("ISBN", values.ISBN);
    fileList.forEach((file, i) => {
      formData.append(`image`, file);
    });

    if (!id) {
      createBooks(formData).then((response) => {
        let errorMessage = "";
        if (response.error && response.error.status === 500) {
          errorMessage = response.error.data.message;
        }
        if (createBookError) {
          errorMessage = createBookError.data.message;
        }
        if (errorMessage) {
          notification.error({
            message: "Failed",
            placement: "bottomRight",
            description: ` ${createBookError?.data.message}`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        } else {
          setIsOpen(false);
          setEditBookData(null);

          bookForm.resetFields();
          setReloadedData(response);

          notification.success({
            message: `Book Created Successfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          refechBooks();
        }
      });
    } else {
      editBooks({ id, payload: formData }).then((response) => {
        let errorMessage = "";
        if (response.error && response.error.status === 500) {
          errorMessage = response.error.data.message;
        }
        if (editBookError) {
          errorMessage = editBookError.data.message;
        }
        if (errorMessage) {
          notification.error({
            message:
              "An error occurred while Creating the Author , try again later.",
            placement: "bottomRight",
            description: `${editBookError?.data?.message}`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        } else {
          setIsOpen(false);
          setEditBookData(null);
          notification.success({
            message: `${editBookData?.bookName} Book Updated Successfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          setReloadedData(response?.data);
          refechBooks();
        }
      });
    }
    refechBooks();
  };

  const handleUpload = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };

  const onFinishFailed = () => {
    notification.error({
      message: "An error occurred while Creating the Author , try again later.",
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
    });
  };
  /*author submit fuction*/
  const submitAuthor = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);

    // Append optional fields if they exist
    if (values.email) {
      formData.append("email", values.email);
    }
    if (values.mobile) {
      formData.append("mobile", values.mobile);
    }
    if (values.experienceInWriting) {
      formData.append("experienceInWriting", values.experienceInWriting);
    }
    if (values.numbersOfBooksPublished) {
      formData.append(
        "numbersOfBooksPublished",
        parseInt(values.numbersOfBooksPublished)
      );
    }
    if (values.averageRatings) {
      formData.append("averageRatings", parseFloat(values.averageRatings));
    }
    fileList.forEach((file, i) => {
      formData.append(`image`, file);
    });

    createAuthors(formData).then((res) => {
      let errorMessage = "";
      if (res.error && res.error.status === 500) {
        errorMessage = res.error.data.message;
      }
      if (createAuthorError) {
        errorMessage = createAuthorError.data.message;
      }
      if (errorMessage) {
        notification.error({
          message:
            "An error occurred while Creating the Book , try again later.",
          placement: "bottomRight",
          // description: `${createAuthorError?.data?.message}`,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        setOpenCreateAuthorModal(false);
      } else {
        setOpenCreateAuthorModal(false);
        setReloadedData(res);
        notification.success({
          message: `Author Created Successfully `,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
        refetchAuthor();
        setOpenCreateAuthorModal(false);
      }
    });
  };

  const handleUploadAuthorImage = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };
  return (
    <>
      <Modal
        title={
          editBookData && Object.keys(editBookData).length > 0
            ? "Edit Book"
            : "Create Book"
        }
        // className="book-modal"s
        open={isOpen}
        width={600}
        onCancel={handleCancel}
        centered
        footer={null}
        wrapClassName="vertical-scroll-modal"
        bodyStyle={{ maxHeight: "400px", overflowY: "auto", overflowX: "clip" }}
        className="addstudent-popup"
      >
        <Form
          // name="BookForm"
          initialValues={editBookData?.id ? { remember: true } : null}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={bookForm}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="bookName"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel className="float-inputs" label="Book Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="bookRate"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label={
                    <span>
                      Book Rate(
                      <BiRupee />)
                    </span>
                  }
                  type="tel"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="categoryId"
                rules={[{ required: true, message: "" }]}
              >
                <SelectFloatLabel
                  className="category-select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  label="Select a Category"
                >
                  {categoryData?.data?.category?.map((category) => (
                    <Option key={category.id} value={category.id}>
                      {category.categoryName.charAt(0).toUpperCase() +
                        category.categoryName.slice(1)}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="authorIds"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  className="float-inputs"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Aauthor Name"
                  style={{ width: 280, overflowY: "scroll" }}
                >
                  {authorsData?.data?.authors?.map((author) => (
                    <Option key={author.id} value={author.id}>
                      {author.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Button
                className="add-books-button"
                style={{ position: "absolute", top: "0px", right: "-90px" }}
                onClick={handleopenCreateAuthorModal}
              >
                <PlusOutlined />
              </Button>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="bookSelfNumbers"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Book Self Numbers"
                  type="tel"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="numbersOfBooks"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Numbers Of Books"
                  type="tel"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item name="ISBN" rules={[{ required: true, message: "" }]}>
                <InputFloatLabel className="float-inputs" label="ISBN" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="publisher"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel className="float-inputs" label="Publisher" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="numberOfRatings"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Number Of Ratings"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="bookDescription"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Book Description"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="image">
                <Upload
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleUpload}
                  multiple
                  showUploadList={true}
                  beforeUpload={() => false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ width: 265, height: 40, borderRadius: 5 }}
                  >
                    Upload Image
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="cancel-button" onClick={handleCancel}>
                  cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* /*author modal*/}
      <Modal
        title="Create Author"
        className="Aauthor-modal"
        open={openCreateAuthorModal}
        width={600}
        onCancel={() => setOpenCreateAuthorModal(false)}
        centered
        footer={null}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Form
          name="AuthorForm"
          onFinish={submitAuthor}
          onFinishFailed={onFinishFailed}
          form={authorForm}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item name="name" rules={[{ required: true, message: "" }]}>
                <InputFloatLabel className="float-inputs" label=" (*) Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="email"
                rules={[
                  // { required: true, message: "" },
                  {
                    type: "email",
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Email"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="mobile"
                rules={[
                  // {
                  //   required: true,
                  //   message: "",
                  // },
                  {
                    min: 10,
                    message: "Please enter valid phone number",
                  },
                  {
                    max: 10,
                    message: "Cannot exceed more than 10",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Mobile"
                  type="tel"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="experienceInWriting"
                // rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Experience(Year)"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="numbersOfBooksPublished"
                // rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Numbers Of Books Published"
                  type="tel"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="averageRatings"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="(*) Average Ratings"
                  type="tel"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="image">
                <Upload
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleUploadAuthorImage}
                  multiple
                  showUploadList={true}
                  beforeUpload={() => false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ width: 310, height: 40, borderRadius: 5 }}
                  >
                    Upload Image (*)
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button
                  className="cancel-button"
                  onClick={() => setOpenCreateAuthorModal(false)}
                >
                  cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateBook;
