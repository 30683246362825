import { Button, Modal, Row, Col, notification } from "antd";
import "./CategoryCards.scss";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  useDeleteCategoryMutation,
  useFindCategoryMutation,
  useGetAllCategoryQuery,
} from "../../RTKQuery/Category";
import { useEffect, useState } from "react";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
import { useNavigate } from "react-router-dom";
import {
  LikeTwoTone,
  UploadOutlined,
  DislikeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
export default function CategoryCards({
  categoryData,
  setCategoryData,
  setIsOpen,
  setCategoryDetails,
  categoryDetails,
  isLoading,
}) {
  const [deleteCategory, { error: deleteCategoryError }] =
    useDeleteCategoryMutation();
  const [findCategory] = useFindCategoryMutation();
  const [viewModalBookDetails, setViewModalBookDetails] = useState({});
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const { refetch: getcategoryDataRefetch } = useGetAllCategoryQuery();
  const navigate = useNavigate();
  const handleClickCategoryCard = (card) => {
    navigate(`/books/category/${card.id}`);
    // localStorage.setItem("selectedItem", "books");
  };

  const handleViewCategory = (card) => {
    findCategory(card.id).then((res) => {
      setViewModalBookDetails(card);
    });
    setIsOpenViewModal(true);
  };
  const handleEditCategory = (card) => {
    setIsOpen(true);
    setCategoryDetails(card);
  };
  const handleDeleteCategory = (card) => {
    Modal.confirm({
      title: "Delete Category?",
      centered: true,
      onOk: () => {
        deleteCategory(card.id).then((response) => {
          let errorMessage = "";
          if (response.error && response.error.status === 500) {
            errorMessage = response.error.data.message;
          }
          if (deleteCategoryError) {
            errorMessage = deleteCategoryError.data.message;
          }
          if (errorMessage) {
            notification.error({
              message: "Delete Failed",
              description: `${deleteCategoryError?.data?.message}`,
              icon: (
                <DislikeOutlined
                  style={{
                    color: "red",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          } else {
            setCategoryData((present) => {
              return present.filter((data) => data.id !== card.id);
            });
            notification.success({
              message: `${card.categoryName} Book Deleted successfully `,
              placement: "bottomRight",
              icon: <LikeTwoTone style={{ color: "#00FF00" }} />,
            });
          }
        });
      },
    });
  };

  return (
    <div className="categories-cards-container">
      {/* {isLoading ? (
        <>
          <Spin className="spin" />
        </>
      ) : categoryData && categoryData.length === 0 ? (
        <>
          <EmptyComponent />
        </>
      ) : (
        <></>
      )} */}
      {categoryData &&
        categoryData.map((card) => {
          return (
            <div>
              <div
                className="category-card"
                onClick={() => handleClickCategoryCard(card)}
                // src={`https://api.edpedia.co/v1.0/instAdmin/media/image?shortUrl=${card.image}`}
                key={card.id}
              >
                <span className="category-card-heading">
                  {card?.categoryName}
                </span>
              </div>
              <div className="author-actions">
                <h3>Actions</h3>
                <div className="icon">
                  <Button onClick={() => handleViewCategory(card)}>
                    <EyeOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      handleEditCategory(card);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      handleDeleteCategory(card);
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}

      <Modal
        title="Category Details"
        visible={isOpenViewModal}
        onCancel={() => setIsOpenViewModal(false)}
        footer={null}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="modal-text" span={9}>
            Category Name
          </Col>
          <Col className="modal-text" span={15}>
            {viewModalBookDetails?.categoryName}
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
