import { Empty } from "antd";
import { useEffect, useState } from "react";
import "./EmptyComponent.scss";
const EmptyComponent = (props) => {
  //   const [nightMode, setNightMode] = useState(false);

  //   useEffect(() => {
  //     const mode = sessionStorage.getItem("darkmode") === "true";
  //     setNightMode(mode);
  //   }, []);

  const DescriptionComponent = () => (
    <div>{props.message ?? "No data available"}</div>
  );

  return (
    <Empty className="custom-empty" description={<DescriptionComponent />} />
  );
};

export default EmptyComponent;
