import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import { booksService } from "../RTKQuery/Books";
import { categoryService } from "../RTKQuery/Category";
import { authorService } from "../RTKQuery/Author";
import { fileService } from "../RTKQuery/FileHandling";
import { LoginedStaffService } from "../RTKQuery/LoginedStaff";
import { instituteDetails } from "../RTKQuery/Institute";
const store = configureStore({
  reducer: {
    [booksService.reducerPath]: booksService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [fileService.reducerPath]: fileService.reducer,
    [authorService.reducerPath]: authorService.reducer,
    [LoginedStaffService.reducerPath]: LoginedStaffService.reducer,
    [instituteDetails.reducerPath]: instituteDetails.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      booksService.middleware,
      categoryService.middleware,
      authorService.middleware,
      fileService.middleware,
      LoginedStaffService.middleware,
      instituteDetails.middleware
    ),
});
export default store;

// const reducer = combineReducers({
//   [booksService.reducerPath]: booksService.reducer,
//   [categoryService.reducerPath]: categoryService.reducer,
// });

// export const store = configureStore({
//   reducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(
//       booksService.middleware,
//       categoryService.middleware
//     ),
// });
