import {
  Table,
  Space,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Select,
  notification,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
import {
  DislikeOutlined,
  LikeTwoTone,
  EyeOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  useCreateRentBookMutation,
  useGetAllRentedBookQuery,
  useGetBookForDopdownQuery,
  useUpdateBookReqStatusMutation,
  useViewRentalBookInformationQuery,
  useViewUserDetailsQuery,
  useBookCollectStatusUpdateMutation,
} from "../../../RTKQuery/Books";
const RentBook = ({ setOpenRentalModal, openRentalModal }) => {
  const [viewModal, setViewModal] = useState(false);
  const [rentBookData, setRentBookData] = useState([]);
  const { data, refetch: refetchGetRentBooks } = useGetAllRentedBookQuery();
  const [rentBookRowData, setRentBookRowData] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [infoData, setInfoData] = useState(null);
  const [isOpenFineBookModal, setIsOpenFineBookModal] = useState(false);
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [rentalStatusUpdateModal, setRentalStatusUpdateModal] = useState(false);
  const [userdetailsDataId, setUserDetailsDataId] = useState(null);
  const [createRentBook, { error: createRentBookError }] =
    useCreateRentBookMutation();
  const { data: getAllBookData } = useGetBookForDopdownQuery();
  const { data: getUserViewDetails } =
    useViewUserDetailsQuery(userdetailsDataId);
  const [updateBookReqStatus, { error: updateBookRequestError }] =
    useUpdateBookReqStatusMutation();
  const { createRentalForm } = Form.useForm();
  const { rentalStatusUpdateForm } = Form.useForm();
  const [collectookStatus, { error: collectedBookError }] =
    useBookCollectStatusUpdateMutation();
  const { Option } = Select;
  const { data: getRentalBookInformation } = useViewRentalBookInformationQuery(
    infoData,
    {
      skip: infoData == null,
    }
  );
  
  useEffect(() => {
    if (data) {
      setRentBookData(data?.data?.rented);
    }
  }, [data]);

  const handleViewRentBooks = (row) => {
    setViewModal(true);
    setSelectedBook(row?.book);
  };
  const handleCancel = () => {
    setViewModal(false);
  };

  const handleRentAmountsOfBook = (row) => {
    const bookId = row?.book?.id;
    setIsOpenFineBookModal(true);
    setInfoData(bookId);
  };

  const handleUpdateRentStatus = (row) => {
    setRentalStatusUpdateModal(true);
    setRentBookRowData(row);
    rentalStatusUpdateForm.resetFields();
  };

  const submitStatus = async (values) => {
    const rentalId = rentBookRowData?.rentalId;
    const payload = {
      ...values,
    };
    updateBookReqStatus({ rentalId: rentalId, ...payload }).then((res) => {
      let errorMessage = "";
      if (res.error && res.error.status === 500) {
        errorMessage = res.error.data.message;
      }
      if (updateBookRequestError) {
        errorMessage = updateBookRequestError.data.message;
      }
      if (errorMessage) {
        notification.error({
          message: "Error",
          placement: "bottomRight",
          description: `${updateBookRequestError.data.message}`,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        setRentalStatusUpdateModal(false);
        rentalStatusUpdateForm.resetFields();
      } else if (res.error === 500) {
        refetchGetRentBooks();
        notification.success({
          message: `Book Returned Successfully`,
          placement: "bottomRight",
          icon: <LikeTwoTone style={{ color: "#00FF00" }} />,
        });
        setRentalStatusUpdateModal(false);
        rentalStatusUpdateForm.resetFields();
        setRentBookRowData(null);
      }
      refetchGetRentBooks();
    });
  };

  const handleCancelRentalBookModal = () => {
    setOpenRentalModal(false);
    createRentalForm.resetFields();
  };
  const [userType, setUserType] = useState("staff");

  const handleUserTypeChange = (value) => {
    setUserType(value);
  };

  const handleOnFinishCreateRentBook = (values) => {
    const params = {
      ...values,
      userId: parseInt(values?.userId),
    };
    createRentBook({ payload: params }).then((res) => {
      let errorMessage = "";
      if (res.error && res.error.status === 500) {
        errorMessage = res.error.data.message;
      }
      if (createRentBookError) {
        errorMessage = createRentBookError.data.message;
      }
      if (errorMessage) {
        notification.error({
          description: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });

        setOpenRentalModal(false);
      } else {
        notification.success({
          message: `Rental Request Created`,
          placement: "bottomRight",
          description: `${selectedBook?.userName} rental request Created.`,
          icon: <LikeTwoTone style={{ color: "#00FF00" }} />,
        });
        refetchGetRentBooks();
        setOpenRentalModal(false);
        createRentalForm.resetFields();
      }
      refetchGetRentBooks();
    });
  };

  const onFinishFailed = () => {
    notification.error({
      message: "Failed",
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
    });
  };
  const handleUserDetails = (row) => {
    const userId = row?.userId;
    const userType = row?.userType;
    setUserDetailsDataId({ userId: userId, userType: userType });
    setUserDetailsModal(true);
  };

  const handleCancelRentalBookStatusUpdate = () => {
    setRentalStatusUpdateModal(false);
    rentalStatusUpdateForm.resetFields();
  };
  const submitHandleCollectBookStatus = (rentalId, status) => {
    const payload = { status };
    collectookStatus({ rentalId, payload })
      .then((res) => {
        if (res.status === 500) {
          notification.error({
            message: "Delete Failed",
            placement: "bottomRight",
            description: `${collectedBookError?.data?.message}`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        }
        const updatedData =
          data &&
          data?.map((item) => {
            if (item.rentalId === rentalId) {
              return {
                ...item,
                bookCollected: status,
              };
            }
            return item;
          });
        setRentBookData(updatedData);
        notification.success({
          message: `Book Collected `,
          placement: "bottomRight",
          icon: <LikeTwoTone style={{ color: "#00FF00" }} />,
        });
        refetchGetRentBooks();
      })
      .catch((error) => {
        console.error("Failed to update Book Collected status", error);
      });
  };

  const columns = [
    {
      title: "Book Name",
      dataIndex: "bookId",
      key: "bookId",
      // width: 180,
      align: "center",
      sorter: (a, b) => a.book.bookName.localeCompare(b.book.bookName),
      render: (_, book) => {
        return book.book.bookName;
      },
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      render: (_, row) => (
        <div>
          {row.userName}
          <Button
            onClick={() => handleUserDetails(row)}
            className="username-btn"
          >
            <EyeOutlined
              style={{
                marginLeft: "5px",
                color: "white",
                backgroundColor: "#172b4d",
                padding: "  2px 4px",
                borderRadius: "5px",
              }}
            />
          </Button>
        </div>
      ),
    },

    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      align: "center",
      sorter: (a, b) => a.userType.localeCompare(b.userType),
    },

    {
      title: "Rented Date",
      dataIndex: "rentalDate",
      key: "rentalDate",
      align: "center",
      sorter: (a, b) => a.rantalDate - b.rentalDate,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      align: "center",
      sorter: (a, b) => a.dueDate - b.dueDate,
    },
    {
      title: "Book Collected",
      dataIndex: "bookCollected",
      key: "bookCollected",
      align: "center",
      render: (bookCollected, record) => (
        <Space>
          <Radio.Group
            onChange={(e) =>
              submitHandleCollectBookStatus(record.rentalId, e.target.value)
            }
            value={bookCollected}
          >
            <Radio value={false}>No</Radio>
            <Radio value={true}>Yes</Radio>
          </Radio.Group>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },

    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "center",
      render: (text, row) => (
        <Space>
          <EyeOutlined onClick={() => handleViewRentBooks(row)} />

          <EditOutlined onClick={() => handleUpdateRentStatus(row)} />

          <InfoCircleOutlined onClick={() => handleRentAmountsOfBook(row)} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div></div>
      <Table
        dataSource={rentBookData}
        columns={columns}
        showSorterTooltip={false}
      />
      {/* /rent view modal/ */}
      <Modal
        open={viewModal}
        title="Rented Books Details"
        onCancel={handleCancel}
        footer={null}
        width={650}
        centered
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="modal-text" span={9}>
            Book Name
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.bookName}
          </Col>
          <Col className="modal-text" span={9}>
            Numbers Of Books
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.numbersOfBooks}
          </Col>
          <Col className="modal-text" span={9}>
            publisher
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.publisher}
          </Col>
          <Col className="modal-text" span={9}>
            ISBN
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.isbn}
          </Col>
          <Col className="modal-text" span={9}>
            Book Description
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.bookDescription}
          </Col>
        </Row>
      </Modal>
      {/* /rent book fine info modal/ */}
      <Modal
        open={isOpenFineBookModal}
        // onCancel={cancelFineBookModal}
        onCancel={() => {
          setIsOpenFineBookModal(false);
        }}
        title="Book Fine Aamount Details"
        className="info"
        footer={null}
        width={600}
        centered
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="modal-text" span={10}>
            RentalDate
          </Col>
          <Col className="modal-text" span={14}>
            {getRentalBookInformation?.data?.rentalDate}
          </Col>
          <Col className="modal-text" span={10}>
            Missing Books FineAmount
          </Col>
          <Col className="modal-text" span={14}>
            {getRentalBookInformation?.data?.missingBooksFineAmount}
          </Col>
          <Col className="modal-text" span={10}>
            Per Day Fine Amount
          </Col>
          <Col className="modal-text" span={14}>
            {getRentalBookInformation?.data?.perDayFineAmount}
          </Col>
          <Col className="modal-text" span={10}>
            RentalDate
          </Col>
          <Col className="modal-text" span={14}>
            {getRentalBookInformation?.data?.rentalDate}
          </Col>
        </Row>
      </Modal>
      {/* /create rental request modal/ */}
      <Modal
        open={rentalStatusUpdateModal}
        onCancel={handleCancelRentalBookStatusUpdate}
        title="Status Update"
        footer={null}
        width={400}
        centered
      >
        <Form
          onFinish={submitStatus}
          form={rentalStatusUpdateForm}
          name="rentalBookUpdateForm"
        >
          <Row>
            <Col span={12}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <SelectFloatLabel
                    label="Status"
                    name="status"
                    style={{ width: 300 }}
                  >
                    <Option value="RETURNED">RETURNED</Option>
                  </SelectFloatLabel>
                </Form.Item>
              </Col>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>

            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button
                  className="cancel-button"
                  onClick={handleCancelRentalBookStatusUpdate}
                >
                  cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* /create rent book request/ */}
      {/* <Modal
        open={openRentalModal}
        title="Rent a Book"
        footer={null}
        onCancel={handleCancelRentalBookModal}
        centered
      >
        <Form
          form={createRentalForm}
          onFinish={handleOnFinishCreateRentBook}
          onFinishFailed={onFinishFailed}
          initialValues={false}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="userId"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="User Id"
                  type="tel"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="bookId"
                rules={[{ required: true, message: "" }]}
              >
                <SelectFloatLabel
                  className="float-inputs"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  label="Book Name"
                  style={{ width: 470 }}
                >
                  {getAllBookData?.data?.books?.map((book) => (
                    <Option key={book.id} value={book.id}>
                      {book.bookName}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="userType"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="User Type"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button
                  className="cancel-button"
                  onClick={handleCancelRentalBookModal}
                >
                  cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal> */}
      <Modal
        open={openRentalModal}
        title="Rent a Book"
        footer={null}
        onCancel={handleCancelRentalBookModal}
        centered
      >
        <Form
          onFinish={handleOnFinishCreateRentBook}
          onFinishFailed={onFinishFailed}
          initialValues={{ userType: "staff" }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="userType"
                rules={[
                  { required: true, message: "Please select a user type" },
                ]}
              >
                <Select
                  className="float-inputs"
                  label="User Type"
                  style={{ width: 470 }}
                  onChange={handleUserTypeChange}
                >
                  <Option value="staff">Staff</Option>
                  <Option value="student">Student</Option>
                </Select>
              </Form.Item>
            </Col>

            {userType === "staff" && (
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="userId"
                  rules={[{ required: true, message: "Please enter User Id" }]}
                >
                  <InputFloatLabel
                    className="float-inputs"
                    label="User Id"
                    type="tel"
                  />
                </Form.Item>
              </Col>
            )}

            {userType === "student" && (
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="rollNo"
                  rules={[
                    { required: true, message: "Please enter Roll Number" },
                  ]}
                >
                  <InputFloatLabel
                    className="float-inputs"
                    label="Roll Number"
                    type="text"
                  />
                </Form.Item>
              </Col>
            )}

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="bookId"
                rules={[{ required: true, message: "Please select a book" }]}
              >
                <SelectFloatLabel
                  className="float-inputs"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  label="Book Name"
                  style={{ width: 470 }}
                >
                  {getAllBookData?.data?.books?.map((book) => (
                    <Option key={book.id} value={book.id}>
                      {book.bookName}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button
                  className="cancel-button"
                  onClick={handleCancelRentalBookModal}
                >
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* /staff and student details/ */}
      <Modal
        title="User Details"
        onCancel={() => setUserDetailsModal(false)}
        open={userDetailsModal}
        footer={null}
        centered
      >
        {getUserViewDetails && (
          <>
            {userdetailsDataId.userType.toLowerCase() == "staff" && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="modal-text" span={12}>
                  Staff Name
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.name}
                </Col>
                <Col span={12} className="modal-text">
                  Contact
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.contact}
                </Col>
                <Col span={12} className="modal-text">
                  Email
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.email}
                </Col>
                <Col span={12} className="modal-text">
                  Address
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.address}
                </Col>
                <Col span={12} className="modal-text">
                  DeptName
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.deptName}
                </Col>
                <Col span={12} className="modal-text">
                  Experience
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.experience}
                </Col>
                <Col span={12} className="modal-text">
                  City:
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.city}
                </Col>
                <Col span={12} className="modal-text">
                  State
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.state}
                </Col>
                <Col span={12} className="modal-text">
                  Country
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.country}
                </Col>
                <Col span={12} className="modal-text">
                  DateOfBirth
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.dateOfBirth}
                </Col>
                <Col span={12} className="modal-text">
                  Nationality
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.nationality}
                </Col>
                <Col span={12} className="modal-text">
                  DeptName
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.deptName}
                </Col>
                <Col span={12} className="modal-text">
                  Gender
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.gender}
                </Col>{" "}
                <Col span={12} className="modal-text">
                  Designation
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.designation}
                </Col>
              </Row>
            )}
          </>
        )}

        {getUserViewDetails && (
          <>
            {userdetailsDataId.userType.toLowerCase() == "student" && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="modal-text" span={12}>
                  Student Name
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.studentName}
                </Col>

                <Col span={12} className="modal-text">
                  ClassName
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.className}
                </Col>
                <Col span={12} className="modal-text">
                  Section
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.section}
                </Col>
                <Col span={12} className="modal-text">
                  DateOfBirth
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.dateOfBirth}
                </Col>
                <Col span={12} className="modal-text">
                  Gender
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.gender}
                </Col>
                <Col span={12} className="modal-text">
                  Contact
                </Col>
                <Col span={12} className="modal-text">
                  {" "}
                  {getUserViewDetails?.data?.contact}
                </Col>

                <Col span={12} className="modal-text">
                  Email
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.email}
                </Col>

                <Col span={12} className="modal-text">
                  City
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.city}
                </Col>
                <Col span={12} className="modal-text">
                  State
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.state}
                </Col>
                <Col span={12} className="modal-text">
                  Address
                </Col>
                <Col span={12} className="modal-text">
                  {getUserViewDetails?.data?.address}
                </Col>
              </Row>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default RentBook;
