import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetBookForDopdownQuery,
  useGetAllFevoriteBooksQuery,
  useGetAllRentedBookQuery,
} from "../../RTKQuery/Books";
import { useGetAllBookRequestQuery } from "../../RTKQuery/Books";
import { useGetAllCategoryQuery } from "../../RTKQuery/Category";
import { useGetAllAuthorsQuery } from "../../RTKQuery/Author";
import { useGetAllSuggesionBookQuery } from "../../RTKQuery/Books";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
import totalBook from "../../assets/Total Books.svg";
import totalAuthor from "../../assets/Total Authors.svg";
import totalMissingBook from "../../assets/Total Missing Books.svg";
import RentedBook from "../../assets/Total Rented Books.svg";
import totalCtegory from "../../assets/total categories.svg";
import totalRequestedBook from "../../assets/Total Requested Books.svg";
import suggestedBook from "../../assets/suggestion.svg";
import returned from "../../assets/Returned.svg";
import { Card, Spin } from "antd";
import "./dashboard.scss";
export default function Dashboard() {
  const [listOfAuthors, setListOfAuthors] = useState(0);
  const [listOfBook, setListOfBook] = useState(0);
  const [listOfRequestedBook, setListOfRequestedBook] = useState(0);
  const [listOfCategory, setListOfCategory] = useState(0);
  const [listOfSuggesionBook, setListOfSuggesionBook] = useState(0);
  const [listOfReturnedBook, setTotalReturnedBook] = useState(0);
  const [listOfMisssingBook, setTotalMissingBook] = useState(0);
  const [fevoriteBook, setFevoriteBook] = useState([]);
  const [listOfRentBook, setTotalRentBook] = useState(0);
  const { data: getAuthor } = useGetAllAuthorsQuery();
  const { data: getBook } = useGetBookForDopdownQuery();
  const { data: getSuggesionBook } = useGetAllSuggesionBookQuery();
  const { data: getRequestBook } = useGetAllBookRequestQuery();
  const { data: getCategory } = useGetAllCategoryQuery();
  const { data: bookRequests } = useGetAllRentedBookQuery();
  const { data: getAllFevoriteBooks, isLoading } =
    useGetAllFevoriteBooksQuery();

  const navigate = useNavigate();
  useEffect(() => {
    if (getSuggesionBook) {
      loadSuggestedBook();
    }
  }, [getSuggesionBook]);
  useEffect(() => {
    if (getAuthor) {
      loadAuthorLists();
    }
  }, [getAuthor]);
  useEffect(() => {
    if (getBook) {
      loadBookLists();
    }
  }, [getBook]);

  useEffect(() => {
    if (getRequestBook) {
      loadRequestBook();
    }
  }, [getRequestBook]);
  useEffect(() => {
    if (getCategory) {
      loadCategoryLists();
    }
  }, [getCategory]);
  useEffect(() => {
    if (getAllFevoriteBooks) {
      setFevoriteBook(getAllFevoriteBooks?.data);
    }
  }, [getAllFevoriteBooks]);

  useEffect(() => {
    if (bookRequests) {
      loadReturnedBook();
      loadMissingBook();
      loadRentedBook();
    }
  }, [bookRequests]);
  const loadAuthorLists = () => {
    if (getAuthor?.data?.authors && getAuthor?.data?.authors?.length !== 0) {
      setListOfAuthors(getAuthor?.data?.authorCount);
    } else {
      setListOfAuthors(0);
    }
  };
  const loadBookLists = () => {
    if (getBook?.data?.books && getBook?.data?.books?.length !== 0) {
      setListOfBook(getBook?.data?.books?.length);
    } else {
      setListOfBook(0);
    }
  };

  const loadRequestBook = () => {
    if (getRequestBook?.data && getRequestBook?.data?.length !== 0) {
      setListOfRequestedBook(getRequestBook?.data?.length);
    } else {
      setListOfRequestedBook(0);
    }
  };
  const loadCategoryLists = () => {
    if (getCategory?.data?.category && getCategory?.data?.category.length) {
      setListOfCategory(getCategory?.data?.categoryCount);
    } else {
      setListOfCategory(0);
    }
  };
  const loadSuggestedBook = () => {
    if (getSuggesionBook?.data && getSuggesionBook?.data.length) {
      setListOfSuggesionBook(getSuggesionBook?.data.length);
    } else {
      setListOfSuggesionBook(0);
    }
  };
  const loadReturnedBook = () => {
    if (bookRequests?.data && bookRequests?.data?.returned.length !== 0) {
      setTotalReturnedBook(bookRequests?.data?.returned?.length);
    } else {
      setTotalReturnedBook(0);
    }
  };
  const loadMissingBook = () => {
    if (bookRequests?.data && bookRequests?.data?.missing.length !== 0) {
      setTotalMissingBook(bookRequests?.data?.missing?.length);
    } else {
      setTotalMissingBook(0);
    }
  };
  const loadRentedBook = () => {
    if (bookRequests?.data && bookRequests?.data?.rented.length !== 0) {
      setTotalRentBook(bookRequests?.data?.rented?.length);
    } else {
      setTotalRentBook(0);
    }
  };
  const handleAuthor = () => {
    navigate("/authors");
  };
  const handleBooks = () => {
    navigate("/books");
  };
  const handleCategory = () => {
    navigate("/categories");
  };
  const handleSuggestedBook = () => {
    navigate("/books", { state: "suggestedBooks" });
  };
  const handleReqestedBook = () => {
    navigate("/books", { state: "requestedBook" });
  };
  const handleMissingBooks = () => {
    navigate("/books", { state: "missingBook" });
  };
  const handleReturnedBook = () => {
    navigate("/books", { state: "returnedBook" });
  };
  const handleRentedBook = () => {
    navigate("/books", { state: "rentedBook" });
  };

  return (
    <main>
      <div className="dashboard-container">
        <div className="dashboard-data-container">
          <div className="card-container card-1" onClick={handleAuthor}>
            <p className="card-name">Total Authors</p>
            <div className="number-icon-container">
              <p className="card-total">{listOfAuthors}</p>
              <img src={totalAuthor} alt="author" className="dashboard-icon " />
            </div>
          </div>

          <div className="card-container card-2" onClick={handleBooks}>
            <p className="card-name">Total Book</p>
            <div className="number-icon-container">
              <p className="card-total">{listOfBook}</p>
              <img src={totalBook} alt="book" className="dashboard-icon " />
            </div>
          </div>
          <div className="card-container card-3" onClick={handleCategory}>
            <p className="card-name">Total Category</p>
            <div className="number-icon-container">
              <p className="card-total">{listOfCategory}</p>
              <img
                src={totalCtegory}
                alt="category"
                className="dashboard-icon "
              />
            </div>
          </div>
          <div className="card-container card-4" onClick={handleReqestedBook}>
            <p className="card-name"> Total Requested Book</p>
            <div className="number-icon-container">
              <p className="card-total">{listOfRequestedBook}</p>
              <img
                src={totalRequestedBook}
                alt="requestedBook"
                className="dashboard-icon "
              />
            </div>
          </div>

          <div className="card-container card-5" onClick={handleMissingBooks}>
            <p className="card-name"> Total Missing Book</p>
            <div className="number-icon-container">
              <p className="card-total">{listOfMisssingBook}</p>
              <img
                src={totalMissingBook}
                alt="missingBook"
                className="dashboard-icon "
              />
            </div>
          </div>
          <div className="card-container card-6" onClick={handleSuggestedBook}>
            <p className="card-name"> Total Suggested Book</p>
            <div className="number-icon-container">
              <p className="card-total">{listOfSuggesionBook}</p>
              <img
                src={suggestedBook}
                alt="user"
                className="suggesion-bulb-icon"
              />
            </div>
          </div>
          <div className="card-container card-7" onClick={handleReturnedBook}>
            <p className="card-name"> Total Returned Book</p>
            <div className="number-icon-container">
              <p className="card-total">{listOfReturnedBook}</p>
              <img src={returned} alt="user" className="suggesion-icon " />
            </div>
          </div>
          <div className="card-container card-8" onClick={handleRentedBook}>
            <p className="card-name">Total Rented Book</p>
            <div className="number-icon-container">
              <p className="card-total">{listOfRentBook}</p>
              <img
                src={RentedBook}
                alt="returnedBook"
                className="dashboard-icon"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="latest-book-lists">
        <h2>Favorite Books</h2>
        {/* <div className="famous-card-div"> */}

        {isLoading ? (
          <>
            <Spin className="spin-fevorite" />
          </>
        ) : getAllFevoriteBooks?.data &&
          getAllFevoriteBooks?.data.length === 0 ? (
          <div className="empty-component-wrapper">
            <EmptyComponent />
          </div>
        ) : (
          <>
            <div className="famous-card-div">
              {fevoriteBook?.map((card, index) => {
                return (
                  <>
                    <Card
                      key={index}
                      style={{
                        marginLeft: 10,
                        marginBottom: 15,
                        cursor: "pointer",
                        borderRadius: 5,
                      }}
                      cover={
                        <img
                          alt="img"
                          src={card.image}
                          style={{ maxHeight: 150 }}
                        />
                      }
                      className="famous-book-cards"
                    >
                      <span>{card.bookName}</span>
                      <span>{card.publisher}</span>
                      <div className="icon-container">
                        <i className="star-icon"></i>
                        <i className="star-icon"></i>
                        <i className="star-icon"></i>
                        <i className="star-icon"></i>
                        <i className="star-icon"></i>
                      </div>
                    </Card>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </main>
  );
}
