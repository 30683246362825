import { Button, Spin } from "antd";
import CategoryCards from "../../components/CategoryCards";
import React, { useEffect, useState } from "react";
import { Input } from "antd";
import categoryButton from "../../assets/Categories.svg";
import { useGetAllCategoryQuery } from "../../RTKQuery/Category";
import "../Categories/Categories.scss";
import CreateCategory from "./CreateCategory/CreateCategory";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";

export default function Categories() {
  const [isOpen, setIsOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [totalNumber, setTotalNumber] = useState(0);
  const { Search } = Input;
  const [searchedText, setSearchedText] = useState("");
  const {
    data: categoryListData,
    isLoading,
    isError,
    refetch: refetchCategory,
  } = useGetAllCategoryQuery(searchedText || "");

  const showModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (categoryListData) {
      setCategoryData(categoryListData?.data?.category);
      setTotalNumber(categoryListData?.data?.categoryCount);
    }
  }, [categoryListData]);

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  return (
    <main>
      <div className="books-main-container">
        <div className="first-item-container">
          <div className="book-total-container">
            <h1 className="books">Categories</h1>
            <div className="books-count-container">
              <p className="books-count">{totalNumber}</p>
            </div>
          </div>
          <Search
            placeholder="Search Category Name"
            onChange={(event) => setSearchedText(event.target.value)}
            style={{ width: 490 }}
          />
          <Button className="add-books-button" onClick={showModal}>
            <img src={categoryButton} alt="add books" className="button-icon" />
            <span style={{ marginLeft: 18 }}>Add Category</span>
          </Button>
          <CreateCategory
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            categoryDetails={categoryDetails}
            setCategoryDetails={setCategoryDetails}
            refetchCategory={refetchCategory}
          />
        </div>
        {/* <CategoryCards
          isLoading={isLoading}
          categoryData={categoryData}
          setCategoryData={setCategoryData}
          setIsOpen={setIsOpen}
          setCategoryDetails={setCategoryDetails}
        /> */}
        {categoryListData &&
        categoryListData.data &&
        categoryListData.data.category.length === 0 ? (
          <div className="ErrorContainer">
            <EmptyComponent />
          </div>
        ) : (
          <CategoryCards
            isLoading={isLoading}
            categoryData={categoryData}
            setCategoryData={setCategoryData}
            setIsOpen={setIsOpen}
            setCategoryDetails={setCategoryDetails}
          />
        )}
      </div>
    </main>
  );
}
