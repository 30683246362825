import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const authorService = createApi({
  reducerPath: "authorService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/library/api",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),

  tagTypes: ["Authors"],
  endpoints: (builder) => ({
    getAllAuthorByName: builder.query({
      query: (params, searchedText) => {
        let url = `/author/getAuthors`;
        if (searchedText) {
          url += `?authorName=${searchedText}`;
        }
        return {
          url: url,
          method: "GET",
          params: params,
        };
      },
    }),
    getAllAuthors: builder.query({
      query: () => {
        return {
          url: `/author/getAuthors`,
          method: "GET",
        };
      },
    }),
    findAuthor: builder.mutation({
      query: (id) => ({
        url: `/author/${id}/find`,
        method: "GET",
      }),
    }),
    deleteAuthor: builder.mutation({
      query: (id) => ({
        url: `/author/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Authors"],
    }),
  }),
});

export const {
  useFindAuthorMutation,
  useDeleteAuthorMutation,
  useGetAllAuthorByNameQuery,
  useGetAllAuthorsQuery,
} = authorService;
