import React, { useState } from "react";
import { Button, Card, Modal, Spin, Col, Row, notification } from "antd";
import "../AuthorsCard/AuthorsCard.scss";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  LikeTwoTone,
  UploadOutlined,
  DislikeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  useFindAuthorMutation,
  useDeleteAuthorMutation,
} from "../../RTKQuery/Author";
import { useNavigate } from "react-router-dom";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
export default function AuthorsCard({
  authorData,
  setIsOpen,
  setAuthorData,
  isLoading,
  setEditAuthor,
}) {
  const [viewModal, setViewModal] = useState(false);
  const [selectedAuthor, setSelectedAuthor] = useState({});
  const [findAuthor] = useFindAuthorMutation();
  const [deleteAuthor, { error: deleteAuthorError }] =
    useDeleteAuthorMutation();
  const navigate = useNavigate();
  const handleViewModal = (author) => {
    setViewModal(true);
    findAuthor(author.id).then((response) => {
      setSelectedAuthor(author);
    });
  };
  const handleEdit = (author) => {
    setIsOpen(true);
    setEditAuthor(author);
  };
  const handleDelete = (author) => {
    Modal.confirm({
      title: "Delete Author?",
      onOk: () => {
        deleteAuthor(author.id).then((response) => {
          let errorMessage = "";
          if (response.error && response.error.status === 500) {
            errorMessage = response.error.data.message;
          }
          if (deleteAuthorError) {
            errorMessage = deleteAuthorError.data.message;
          }
          if (errorMessage) {
            notification.error({
              message: "Delete Failed",
              placement: "bottomRight",
              description: `${deleteAuthorError?.data?.message}`,
              icon: <DislikeOutlined style={{ color: "red" }} />,
            });
          } else {
            setAuthorData((present) => {
              return present.filter((data) => data.id !== author.id);
            });
            notification.success({
              message: ` ${author.name} Author Deleted Successfully .`,
              placement: "bottomRight",
              icon: <LikeTwoTone style={{ color: "#00FF00" }} />,
            });
          }
        });
      },
    });
  };

  const handleClickAuthorCard = (author) => {
    navigate(`/books/author/${author.id}`);
  };

  const handleButtonClick = (e, author, action) => {
    e.stopPropagation();
    switch (action) {
      case "view":
        handleViewModal(author);
        break;
      case "edit":
        handleEdit(author);
        break;
      case "delete":
        handleDelete(author);
        break;
      default:
        break;
    }
  };
  return (
    <div className="author_card_container">
      {isLoading ? (
        <>
          <Spin className="spin" />
        </>
      ) : authorData && authorData.length === 0 ? (
        <>
          <div className="empty">
            <EmptyComponent />
          </div>
        </>
      ) : (
        <>
          {authorData.map((author) => (
            <div
              className="authors-card-container"
              onClick={() => handleClickAuthorCard(author)}
            >
              <img src={author.image} alt="img" class="card-image" />
              <div className="author-card-content-container">
                <p className="authore-genre">{author.name}</p>
                <p className="author-genre">{author.email}</p>
                <p className="author-genres">
                  Number Of Books Published :{" "}
                  {author.numbersOfBooksPublished || 0}
                </p>
                <div className="author-actions">
                  <h3>Actions</h3>
                  <div className="icon">
                    <Button
                      onClick={(e) => handleButtonClick(e, author, "view")}
                    >
                      <EyeOutlined />
                    </Button>
                    <Button
                      onClick={(e) => handleButtonClick(e, author, "edit")}
                    >
                      <EditOutlined />
                    </Button>
                    <Button
                      onClick={(e) => handleButtonClick(e, author, "delete")}
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      <Modal
        title="Author Details"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        footer={null}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="modal-text" span={14}>
            Author Name
          </Col>
          <Col className="modal-text" span={10}>
            {selectedAuthor?.name}
          </Col>

          <Col className="modal-text" span={14}>
            Mobile
          </Col>
          <Col className="modal-text" span={10}>
            {selectedAuthor?.mobile}
          </Col>
          <Col className="modal-text" span={14}>
            Experince In Year
          </Col>
          <Col className="modal-text" span={10}>
            {selectedAuthor?.experienceInWriting}
          </Col>
          <Col className="modal-text" span={14}>
            Email
          </Col>
          <Col className="modal-text" span={10}>
            {selectedAuthor?.email}
          </Col>

          <Col className="modal-text" span={14}>
            Number Of Published Book
          </Col>
          <Col className="modal-text" span={10}>
            {selectedAuthor?.numbersOfBooksPublished}
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
