import React, { useEffect, useState } from "react";
import { Input, Tabs, Button, Spin } from "antd";
import "./Authors.scss";
import AuthorsCard from "../../components/AuthorsCard";
import CreateAuthor from "./CreateAuthors/CreateAuthor";
import AauthorButton from "../../assets/Author.svg";
import { useGetAllAuthorByNameQuery } from "../../RTKQuery/Author";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
const { TabPane } = Tabs;
const { Search } = Input;
const tabsData = [
  {
    tabName: "A",
    key: "1",
    tabContent: "Tab A",
  },
  {
    tabName: "B",
    key: "2",
    tabContent: "Tab B",
  },
  {
    tabName: "C",
    key: "3",
    tabContent: "Tab C",
  },
  {
    tabName: "D",
    key: "4",
    tabContent: "Tab D",
  },
  {
    tabName: "E",
    key: "5",
    tabContent: "Tab E",
  },
  {
    tabName: "F",
    key: "6",
    tabContent: "Tab F",
  },
  {
    tabName: "G",
    key: "7",
    tabContent: "Tab G",
  },
  {
    tabName: "H",
    key: "8",
    tabContent: "Tab H",
  },
  {
    tabName: "I",
    key: "9",
    tabContent: "Tab I",
  },
  {
    tabName: "J",
    key: "10",
    tabContent: "Tab J",
  },
  {
    tabName: "K",
    key: "11",
    tabContent: "Tab K",
  },
  {
    tabName: "L",
    key: "12",
    tabContent: "Tab L",
  },
  {
    tabName: "M",
    key: "13",
    tabContent: "Tab M",
  },
  {
    tabName: "N",
    key: "14",
    tabContent: "Tab N",
  },
  {
    tabName: "O",
    key: "15",
    tabContent: "Tab O",
  },
  {
    tabName: "P",
    key: "16",
    tabContent: "Tab P",
  },
  {
    tabName: "Q",
    key: "17",
    tabContent: "Tab Q",
  },
  {
    tabName: "R",
    key: "18",
    tabContent: "Tab R",
  },
  {
    tabName: "S",
    key: "19",
    tabContent: "Tab S",
  },
  {
    tabName: "T",
    key: "20",
    tabContent: "Tab T",
  },
  {
    tabName: "U",
    key: "21",
    tabContent: "Tab U",
  },
  {
    tabName: "V",
    key: "22",
    tabContent: "Tab V",
  },
  {
    tabName: "W",
    key: "23",
    tabContent: "Tab W",
  },
  {
    tabName: "X",
    key: "24",
    tabContent: "Tab X",
  },
  {
    tabName: "Y",
    key: "25",
    tabContent: "Tab Y",
  },
  {
    tabName: "Z",
    key: "26",
    tabContent: "Tab Z",
  },
];

export default function Authors() {
  const [isOpen, setIsOpen] = useState(false);
  const [authorData, setAuthorData] = useState([]);
  const [editAuthor, setEditAuthor] = useState(null);
  const [reloadedData, setReloadedData] = useState([]);
  const [paramsData, setParamsData] = useState("");
  const [totalAuthorNumber, setTotalAuthorNumber] = useState(0);
  const [searchedText, setSearchedText] = useState("");
  const params = { paramsData, authorName: searchedText };
  const {
    data: filteredAuthorData,
    isLoading,
    isError,
    refetch: refechAuthors,
  } = useGetAllAuthorByNameQuery(params || "");

  useEffect(() => {
    if (filteredAuthorData) {
      loadAuthorListData(filteredAuthorData?.data?.authors);
      loadTotalAuthorData();
    }
  }, [filteredAuthorData, reloadedData, searchedText]);
  const loadAuthorListData = () => {
    setAuthorData(filteredAuthorData?.data?.authors);
  };

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  const loadTotalAuthorData = () => {
    if (
      filteredAuthorData?.data?.authors &&
      filteredAuthorData?.data?.authors.length !== 0
    ) {
      setTotalAuthorNumber(filteredAuthorData?.data?.authorCount);
    } else {
      setTotalAuthorNumber(0);
    }
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const handleTabClick = (key) => {
    const params = {
      firstAlphabet: key.toLowerCase(),
    };
    setParamsData(params);
  };
  const getFilteredAuthors = (tabName) => {
    return filteredAuthorData?.data?.authors?.filter((author) =>
      author.name.toLowerCase().startsWith(tabName.toLowerCase())
    );
  };
  return (
    <main>
      <div className="books-main-container">
        <div className="first-item-container">
          <div className="book-total-container">
            <h1 className="books">Authors</h1>
            <div className="books-count-container">
              <p className="books-count">{totalAuthorNumber}</p>
            </div>
          </div>
          <Search
            placeholder="Search Author Name"
            style={{ width: 490 }}
            onChange={(event) => setSearchedText(event.target.value)}
          />
          <Button className="add-books-button" onClick={showModal}>
            <img src={AauthorButton} alt="add books" className="button-icon" />
            <span style={{ marginLeft: 18 }}>Add Author</span>
          </Button>
          {isOpen && (
            <CreateAuthor
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setReloadedData={setReloadedData}
              editAuthor={editAuthor}
              setEditAuthor={setEditAuthor}
              refechAuthors={refechAuthors}
            />
          )}
        </div>
        <div className="second-item-container">
          <Tabs
            defaultActiveKey="all"
            //  onChange={handleTabClick}
          >
            <TabPane tab={<span className="tab">All</span>} key="all">
              <div className="authors-cards-main-container">
                <AuthorsCard
                  authorData={authorData}
                  setIsOpen={setIsOpen}
                  editAuthor={editAuthor}
                  setAuthorData={setAuthorData}
                  isLoading={isLoading}
                  setEditAuthor={setEditAuthor}
                />
              </div>
            </TabPane>

            {tabsData.map((tabs) => (
              <TabPane
                tab={tabs.tabName}
                key={tabs.tabName}
                onClick={() => handleTabClick(tabs.tabName)}
              >
                <div className="authors-cards-main-container">
                  <AuthorsCard
                    authorData={getFilteredAuthors(tabs.tabName)}
                    setIsOpen={setIsOpen}
                    editAuthor={editAuthor}
                    setAuthorData={setAuthorData}
                    isLoading={isLoading}
                    setEditAuthor={setEditAuthor}
                  />
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </main>
  );
}
