import { Table, Space, Modal, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { useGetAllRentedBookQuery } from "../../../RTKQuery/Books";
const PendingBook = () => {
  const [pendingBook, setPendingBook] = useState([]);
  const { data } = useGetAllRentedBookQuery();
  const [viewModal, setViewModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  useEffect(() => {
    setPendingBook(data?.data?.pending);
  }, [data]);
  const handleMissingBook = (row) => {
    setSelectedBook(row?.book);
    setViewModal(true);
  };

  const cancelFineBookModa = () => {
    setViewModal(false);
  };
  const columns = [
    {
      title: "Book Name",
      dataIndex: "bookName",
      key: "bookName",
      align: "center",
      render: (_, book) => {
        return book?.book?.bookName;
      },
      sorter: (a, b) => a.book.bookName.localeCompare(b.book.bookName),
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      align: "center",
      sorter: (a, b) => a.userType.localeCompare(b.userType),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      align: "center",
      sorter: (a, b) => a.dueDate - b.dueDate,
    },
    {
      title: "Fine Amount",
      dataIndex: "fineAmount",
      key: "fineAmount",
      align: "center",
      sorter: (a, b) => a.fineAmount - b.fineAmount,
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "center",
      render: (text, row) => (
        <Space>
          <EyeOutlined onClick={() => handleMissingBook(row)} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={pendingBook}
        columns={columns}
        showSorterTooltip={false}
      />

      <Modal
        open={viewModal}
        title="Returned Book details"
        onCancel={cancelFineBookModa}
        footer={null}
        width={650}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="modal-text" span={9}>
            Book Name
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.bookName}
          </Col>
          <Col className="modal-text" span={9}>
            Numbers Of Books
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.numbersOfBooks}
          </Col>
          <Col className="modal-text" span={9}>
            publisher
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.publisher}
          </Col>
          <Col className="modal-text" span={9}>
            ISBN
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.isbn}
          </Col>
          <Col className="modal-text" span={9}>
            Book Description
          </Col>
          <Col className="modal-text" span={15}>
            {selectedBook?.bookDescription}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default PendingBook;
