import React, { useState } from "react";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  LikeTwoTone,
} from "@ant-design/icons";
import { Button, Card, Modal, Spin, Row, Col, notification } from "antd";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
import { BiRupee } from "react-icons/bi";
import "./BookCards.scss";
import {
  useFindBookMutation,
  useDeleteBookMutation,
} from "../../RTKQuery/Books";
import { useGetAllAuthorByNameQuery } from "../../RTKQuery/Author";
import { useGetAllCategoryQuery } from "../../RTKQuery/Category";
import { DislikeOutlined } from "@ant-design/icons";
const BookCard = ({
  bookData,
  setEditBookData,
  setIsOpen,
  setBookData,
  isLoading,
}) => {
  const [viewModalBookDetails, setViewModalBookDetails] = useState({});
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [findBook] = useFindBookMutation();
  const [deleteBook, { error: bookDeleteError }] = useDeleteBookMutation();
  const { data } = useGetAllAuthorByNameQuery();
  const { data: categoryListData } = useGetAllCategoryQuery();

  const handleEditBooks = (card) => {
    setIsOpen(true);
    setEditBookData(card);
  };
  const handleViewBooks = (card) => {
    setIsOpenViewModal(true);
    setEditBookData(null);

    findBook(card.id).then((res) => {
      setViewModalBookDetails(card);
    });
  };
  const handleDelete = (card) => {
    Modal.confirm({
      title: "Delete Book?",
      onOk: () => {
        deleteBook(card.id)
          .then((response) => {
            let errorMessage = "";
            if (response.error && response.error.status === 500) {
              errorMessage = response.error.data.message;
            }
            if (bookDeleteError) {
              errorMessage = bookDeleteError.data.message;
            }
            if (errorMessage) {
              notification.error({
                message: "Delete Failed",
                description: `${bookDeleteError?.data?.message}`,
                icon: (
                  <DislikeOutlined
                    style={{
                      color: "red",
                    }}
                  />
                ),
                duration: 3,
                placement: "bottomRight",
              });
            } else {
              setBookData((present) => {
                return present.filter((data) => data.id !== card.id);
              });
              notification.success({
                message: `${card?.bookName} Book Deleted successfully `,
                placement: "bottomRight",
                icon: <LikeTwoTone style={{ color: "#00FF00" }} />,
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting book:", error);
            notification.error({
              message: "Error",
              description:
                "An error occurred while deleting the book. Please try again later.",
              icon: (
                <DislikeOutlined
                  style={{
                    color: "red",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          });
      },
    });
  };

  const authorIds = viewModalBookDetails?.authorIds;
  const authorNames = Array.isArray(authorIds)
    ? authorIds.map((authorId) => {
        const author = data?.data?.authors.find(
          (author) => author.id == authorId
        );
        return author ? author?.name : "";
      })
    : [];

  const categoryName = categoryListData?.data?.category?.find((category) => {
    return category.id == viewModalBookDetails?.categoryId;
  })?.categoryName;

  return (
    <>
      <div>
        {isLoading ? (
          <>
            <Spin className="spin" />
          </>
        ) : bookData && bookData.length === 0 ? (
          <div className="empty-component-wrapper">
            <EmptyComponent />
          </div>
        ) : (
          <>
            <div className="cards-container">
              {isLoading ? (
                <>
                  <Spin className="spin" />
                </>
              ) : bookData && bookData.length === 0 ? (
                <div className="empty-component-wrapper">
                  <EmptyComponent />
                </div>
              ) : (
                <>
                  {bookData &&
                    bookData.map((card, index) => {
                      return (
                        <div className="authors-card-container" key={index}>
                          <img src={card.image} alt="img" class="card-image" />
                          {/* <div className="self-number-container">
                            <span>bookSelfNumbers-{card.bookSelfNumbers}</span>
                            <span>bookSelfNumbers-{card.}</span>
                          </div> */}
                          <div className="intro">
                            <span className="book-rate books-name">
                              {card.bookName}
                            </span>
                            <span className="book-rate span ">
                              {card.bookRate} Rate Of The Book
                            </span>
                            <span className="selfNum">
                              bookSelfNumbers : {card.bookSelfNumbers}
                            </span>

                            <div className="author-actions">
                              <h3>Actions</h3>
                              <div className="icon">
                                <Button onClick={() => handleViewBooks(card)}>
                                  <EyeOutlined />
                                </Button>
                                <Button onClick={() => handleEditBooks(card)}>
                                  <EditOutlined />
                                </Button>
                                <Button onClick={() => handleDelete(card)}>
                                  <DeleteOutlined />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Modal
        title="Book Details"
        open={isOpenViewModal}
        onCancel={() => setIsOpenViewModal(false)}
        footer={null}
        width={650}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="modal-text" span={9}>
            Book Name
          </Col>
          <Col className="modal-text" span={15}>
            {viewModalBookDetails?.bookName}
          </Col>

          <Col className="modal-text" span={9}>
            Book Rate
          </Col>
          <Col className="modal-text" span={15}>
            <BiRupee /> {viewModalBookDetails?.bookRate}
          </Col>
          <Col className="modal-text" span={9}>
            Available Books
          </Col>
          <Col className="modal-text" span={15}>
            {viewModalBookDetails?.booksAvailable}
          </Col>

          <Col className="modal-text" span={9}>
            Category Name
          </Col>
          <Col className="modal-text" span={15}>
            {categoryName}
          </Col>
          <Col className="modal-text" span={9}>
            Aauthor Name
          </Col>
          <Col className="modal-text" span={15}>
            {authorNames.join(" , ")}
          </Col>

          <Col className="modal-text" span={9}>
            book Self Numbers
          </Col>
          <Col className="modal-text" span={15}>
            {viewModalBookDetails?.bookSelfNumbers}
          </Col>

          <Col className="modal-text" span={9}>
            Numbers Of Books
          </Col>
          <Col className="modal-text" span={15}>
            {viewModalBookDetails?.numbersOfBooks}
          </Col>
          <Col className="modal-text" span={9}>
            publisher
          </Col>
          <Col className="modal-text" span={15}>
            {viewModalBookDetails?.publisher}
          </Col>
          <Col className="modal-text" span={9}>
            ISBN
          </Col>
          <Col className="modal-text" span={15}>
            {viewModalBookDetails?.isbn}
          </Col>
          <Col className="modal-text" span={9}>
            Book Description
          </Col>
          <Col className="modal-text" span={15}>
            {viewModalBookDetails?.bookDescription}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default BookCard;
