import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const categoryService = createApi({
  reducerPath: "categoryService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/library/api",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Categories"],
  endpoints: (builder) => ({
    getAllCategory: builder.query({
      query: (searchedText) => {
        let url = `/category/getCategories`;
        if (searchedText) {
          url += `?categoryName=${searchedText}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    createCategory: builder.mutation({
      query: ({ payload }) => ({
        url: "/category/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Categories"],
    }),

    editCategory: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/category/${id}/updateCategory`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/category/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Categories"],
    }),
    findCategory: builder.mutation({
      query: (id) => ({
        url: `/category/${id}/find`,
        method: "GET",
      }),
      invalidatesTags: ["Categories"],
    }),
  }),
});

export const {
  useGetAllCategoryQuery,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useFindCategoryMutation,
  useEditCategoryMutation,
} = categoryService;
