import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import Dashboard from "./containers/Dashboard/dashboard";
import Footer from "./components/Footer/Footer";
import Books from "./containers/Books/books";
import Categories from "./containers/Categories/Categories";
import Authors from "./containers/Authors/Authors";
import Profile from "./components/Header/Profile/Profile";
import { useEffect, useState } from "react";
import { notification } from "antd";
import { checkAuth } from "./Utils/cookie-helper";
import Cookies from "js-cookie";
function App() {
  const [isAuth, setIsAuth] = useState(false);
  const branchCode = Cookies.get("branchCode")
  const userId = Cookies.get("userId")
  // https://api.edpedia.co/v1.0/instAdmin/api/staff/staffId//find

  useEffect(() => {
    const socket = new SockJS("https://api.edpedia.co/v1.0/instAdmin/ws");
    const stompClient = Stomp.over(socket);

    const connectCallback = (frame) => {
      stompClient.subscribe(`/topic/${branchCode}/library/staff/${userId}`, (greeting) => {
        const response = JSON.parse(greeting.body);
        notification.success({
          message: (
            <div>
              <strong>{response.message}</strong>
              <div style={{ textAlign: "end" }}>
                <p>From</p>
                <em>{response.sender}</em>
              </div>
            </div>
          ),
          // description: ,
          duration: 5,
          placement: "topRight",
        });
      });
    };

    stompClient.connect({}, connectCallback);

    return () => {
      stompClient.disconnect();
    };
  }, []);

  const getToken = () => {
    const token = Cookies.get("accessToken");
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
      window.location.replace("https://sso.edpedia.co/");
    }
  };

  useEffect(() => {
    let token = Cookies.get("accessToken");
    if (token === null || token === "" || (token === undefined && !isAuth)) {
      checkAuth();
    } else if (token) {
      setIsAuth(true);
      checkAuth();
    }
  }, [isAuth]);

  const logOut = () => {
    setIsAuth(false);
    Cookies.remove("accessToken");
    window.location.replace("https://sso.edpedia.co");
  };

  return (
    <>
      {isAuth && (
        <>
          <Header />
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/books" element={<Books />} />
            <Route exact path="/categories" element={<Categories />} />
            <Route exact path="/authors" element={<Authors />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route
              exact
              path="/books/category/:categoryId"
              element={<Books />}
            />
            <Route exact path="/books/author/:authorIds" element={<Books />} />
            <Route
              exact
              path="/books/activeTab=:activeTab"
              element={<Books />}
            />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
