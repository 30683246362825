import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const fileService = createApi({
  reducerPath: "fileService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/library/api",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    createAuthors: builder.mutation({
      query: (payload) => ({
        url: "/author/create",
        method: "POST",
        body: payload,
      }),
    }),
    editAuthors: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `/author/${id}/updateAuthor`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    createBooks: builder.mutation({
      query: (payload) => {
        return {
          url: "/book/create",
          method: "POST",
          body: payload,
        };
      },
    }),
    editBooks: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `/book/${id}/update`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useCreateBooksMutation,
  useCreateAuthorsMutation,
  useEditAuthorsMutation,
  useEditBooksMutation,
} = fileService;
