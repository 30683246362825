import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Upload,
  Space,
  notification,
} from "antd";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import {
  LikeTwoTone,
  DislikeOutlined,
  UploadOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  useCreateAuthorsMutation,
  useEditAuthorsMutation,
} from "../../../RTKQuery/FileHandling";
const CreateAuthor = ({
  isOpen,
  setIsOpen,
  editAuthor,
  setEditAuthor,
  setReloadedData,
  refechAuthors,
}) => {
  const [authorForm] = Form.useForm();
  const [createAuthors, { error: createAuthorError }] =
    useCreateAuthorsMutation();
  const [editAuthors, { error: editAauthorError }] = useEditAuthorsMutation();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (editAuthor?.id) {
      authorForm.setFieldsValue({
        name: editAuthor?.name,
        mobile: editAuthor?.mobile,
        experienceInWriting: editAuthor?.experienceInWriting,
        averageRatings: editAuthor?.averageRatings,
        email: editAuthor?.email,
        numbersOfBooksPublished: editAuthor?.numbersOfBooksPublished,
        categoryIds: editAuthor?.categoryName,
      });
    } else {
      authorForm.resetFields();
    }
  }, [editAuthor, authorForm]);

  const onFinish = (values) => {
    const id = editAuthor?.id;
    const formData = new FormData();
    // formData.append("name", values.name);
    // formData.append("mobile", values.mobile || "");
    // formData.append("experienceInWriting", values.experienceInWriting || "");
    // formData.append("email", values.email || "");
    // formData.append("averageRatings", parseFloat(values.averageRatings) || "");
    // formData.append(
    //   "numbersOfBooksPublished",
    //   parseInt(values.numbersOfBooksPublished) || ""
    // );
    formData.append("name", values.name);

    // Append optional fields if they exist
    if (values.email) {
      formData.append("email", values.email);
    }
    if (values.mobile) {
      formData.append("mobile", values.mobile);
    }
    if (values.experienceInWriting) {
      formData.append("experienceInWriting", values.experienceInWriting);
    }
    if (values.numbersOfBooksPublished) {
      formData.append(
        "numbersOfBooksPublished",
        parseInt(values.numbersOfBooksPublished)
      );
    }
    if (values.averageRatings) {
      formData.append("averageRatings", parseFloat(values.averageRatings));
    }

    fileList.forEach((file, i) => {
      formData.append(`image`, file);
    });
    if (id) {
      editAuthors({ id, payload: formData }).then((res) => {
        let errorMessage = "";
        if (res.error && res.error.status === 500) {
          errorMessage = res.error.data.message;
        }
        if (editAauthorError) {
          errorMessage = editAauthorError.data.message;
        }
        if (errorMessage) {
          notification.error({
            message:
              "An error occurred while Creating the Author , try again later.",
            placement: "bottomRight",
            description: `${editAauthorError?.data?.message}`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
          setIsOpen(false);
        } else {
          setIsOpen(false);
          setReloadedData(res.data.data);
          setEditAuthor(null);

          notification.success({
            message: `${editAuthor?.name} Author Updated Successfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          refechAuthors();
          setIsOpen(false);
        }
      });
    } else {
      createAuthors(formData).then((res) => {
        let errorMessage = "";
        if (res.error && res.error.status === 500) {
          errorMessage = res.error.data.message;
        }
        if (createAuthorError) {
          errorMessage = createAuthorError.data.message;
        }
        if (errorMessage) {
          notification.error({
            message:
              "An error occurred while Creating the Book , try again later.",
            placement: "bottomRight",
            // description: `${createAuthsorError?.data?.message}`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
          setIsOpen(false);
        } else {
          setIsOpen(false);
          setReloadedData(res);
          setEditAuthor(null);

          notification.success({
            message: `Author Created Successfully .`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          refechAuthors();
        }
      });
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setEditAuthor(null);
    authorForm.resetFields();
  };

  const handleUpload = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };

  const onFinishFailed = () => {
    notification.error({
      message: "An error occurred while Creating the Author , try again later.",
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
    });
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        title={
          editAuthor && Object.keys(editAuthor).length > 0
            ? "Edit Author"
            : "Create Author"
        }
        className="Aauthor-modal"
        open={isOpen}
        width={600}
        onCancel={handleCancel}
        centered
        footer={null}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Form
          name="AuthorForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={authorForm}
          initialValues={editAuthor?.id ? { remember: true } : null}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item name="name" rules={[{ required: true, message: "" }]}>
                <InputFloatLabel className="float-inputs" label="(*) Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="email"
                rules={[
                  // { required: true, message: "" },
                  {
                    type: "email",
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Email"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="mobile"
                rules={[
                  // {
                  //   required: true,
                  //   message: "",
                  // },
                  {
                    min: 10,
                    message: "Please enter valid phone number",
                  },
                  {
                    max: 10,
                    message: "Cannot exceed more than 10",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Mobile"
                  type="tel"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="experienceInWriting"
                // rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Experience(Year)"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="numbersOfBooksPublished"
                // rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Numbers Of Books Published"
                  type="tel"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="averageRatings"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="(*) Average Ratings"
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="image">
                <Upload
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleUpload}
                  multiple
                  showUploadList={true}
                  beforeUpload={() => false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ width: 270, height: 40, borderRadius: 5 }}
                  >
                    Upload Image (*)
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="cancel-button" onClick={handleCancel}>
                  cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateAuthor;
