import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const LoginedStaffService = createApi({
  reducerPath: "LoginedStaffService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/instAdmin/api",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Categories"],
  endpoints: (builder) => ({
    findStaff: builder.query({
      query: (staffId) => {
        return {
          url: `/staff/${staffId}/find`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useFindStaffQuery } = LoginedStaffService;
