import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const booksService = createApi({
  reducerPath: "booksService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/library/api",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),

  tagTypes: ["Books"],
  endpoints: (builder) => ({
    getAllBooks: builder.query({
      query: (searchedText) => {
        let url = `/book/getBooks`;
        if (searchedText) {
          url += `?bookName=${searchedText}`;
        }

        return {
          url: url,
          method: "GET",
        };
      },
      invalidatesTags: ["Books"],
    }),
    getBookForDopdown: builder.query({
      query: () => {
        return {
          url: `/book/getBooks`,
          method: "GET",
        };
      },
      invalidatesTags: ["Books"],
    }),

    getAllBookRequest: builder.query({
      query: () => {
        return {
          url: "/bookRequest/getAllBookRequest",
          method: "GET",
        };
      },
    }),
    getAllSuggesionBook: builder.query({
      query: () => {
        return {
          url: "/book/getSuggestion",
          method: "GET",
        };
      },
    }),
    getAllRentedBook: builder.query({
      query: () => {
        return {
          url: "/rental/allBooksByStatus",
          method: "GET",
        };
      },
    }),
    getAllDueDateCompletedBook: builder.query({
      query: () => {
        return {
          url: "/rental/overDueListBySort",
          method: "GET",
        };
      },
    }),
    getAllFevoriteBooks: builder.query({
      query: (params) => {
        return {
          url: "/rental/mostFavoriteBooks",
          method: "GET",
          params: params,
        };
      },
    }),
    findBook: builder.mutation({
      query: (id) => ({
        url: `/book/${id}/find`,
        method: "GET",
      }),
      invalidatesTags: ["Books"],
    }),
    deleteBook: builder.mutation({
      query: (id) => ({
        url: `/book/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Books"],
    }),
    viewRentalBookInformation: builder.query({
      query: (bookId) => {
        return {
          url: `/rental/rentalInformationByBook?bookId=${bookId}`,
          method: "GET",
        };
      },
    }),
    viewUserDetails: builder.query({
      query: ({ userId, userType }) => {
        return {
          url: `/rental/userDetails?userId=${userId}&userType=${userType}`,
          method: "GET",
        };
      },
    }),
    requestedBookStatusUpdate: builder.mutation({
      query: ({ bookId, ...payload }) => {
        return {
          url: `/bookRequest/${bookId}/updateBookReqStatus`,
          method: "POST",
          body: payload,
        };
      },
    }),
    createRentBook: builder.mutation({
      query: ({ payload }) => {
        return {
          url: `/rental/rentBook`,
          method: "POST",
          body: payload,
        };
      },
    }),

    updateBookReqStatus: builder.mutation({
      query: ({ rentalId, ...payload }) => {
        return {
          url: `/rental/returnBook?rentalId=${rentalId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    bookCollectStatusUpdate: builder.mutation({
      query: ({ rentalId, payload }) => ({
        url: `/rental/bookCollected?rentalId=${rentalId}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["books"],
    }),
  }),
});

export const {
  useGetBookForDopdownQuery,
  useGetAllBooksQuery,
  useGetAllBookRequestQuery,
  useFindBookMutation,
  useDeleteBookMutation,
  useGetAllSuggesionBookQuery,
  useGetAllRentedBookQuery,
  useGetAllFevoriteBooksQuery,
  useViewRentalBookInformationQuery,
  useRequestedBookStatusUpdateMutation,
  useCreateRentBookMutation,
  useUpdateBookReqStatusMutation,
  useViewUserDetailsQuery,
  useGetAllDueDateCompletedBookQuery,
  useBookCollectStatusUpdateMutation,
} = booksService;
// http://15.207.40.153:8017/v1.0/api/rental/userDetails?userId=3&userType=staff
