import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  Row,
  Col,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  EyeOutlined,
  EditOutlined,
  DislikeOutlined,
  LikeTwoTone,
} from "@ant-design/icons";
import {
  useRequestedBookStatusUpdateMutation,
  useGetAllBookRequestQuery,
  useGetBookForDopdownQuery,
} from "../../../RTKQuery/Books";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
const RequestedBook = () => {
  const [requestBookForm] = Form.useForm();
  const [requestedBookData, setRequestedBookData] = useState([]);
  const [editRequestBookData, setEditRequestBookData] = useState([]);
  const [selectedRequestBook, setSelectedRequestBook] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [updateStatusModalOpen, setUpdateStatusModalOpen] = useState(false);
  const { data, refetch: refechRequestBookData } = useGetAllBookRequestQuery();
  // const { data: getAllBookData } = useGetBookForDopdownQuery();
  const [requestedBookStatusUpdate] = useRequestedBookStatusUpdateMutation({});
  const { Option } = Select;

  useEffect(() => {
    if (data) {
      setRequestedBookData(data?.data);
    }
  }, [data]);
  useEffect(() => {
    if (editRequestBookData?.id) {
      requestBookForm.setFieldsValue({
        status: editRequestBookData?.status,
        comments: editRequestBookData?.comments,
      });
    } else {
      requestBookForm.resetFields();
    }
  }, [editRequestBookData, requestBookForm]);

  const handleViewRequesteddBooks = (row) => {
    setViewModal(true);
    setSelectedRequestBook(row?.book);
  };
  const handleCancel = () => {
    setViewModal(false);
  };

  const handleUpdateStatus = (row) => {
    setUpdateStatusModalOpen(true);
    setEditRequestBookData(row);
  };
  const cancelUpdateStatusModal = () => {
    setUpdateStatusModalOpen(false);
    setEditRequestBookData(null);
    requestBookForm.resetFields();
  };

  const onFinish = (values) => {
    const bookReqId = editRequestBookData?.id;
    const payload = {
      ...values,
    };
    requestedBookStatusUpdate({ bookId: bookReqId, ...payload }).then((res) => {
      if (res.error) {
        setUpdateStatusModalOpen(false);
        notification.error({
          placement: "bottomRight",
          description: `You have reached the maximum limit of rented books.`,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      } else {
        notification.success({
          message: ` Status  Update`,
          placement: "bottomRight",
          icon: <LikeTwoTone style={{ color: "#00FF00" }} />,
        });
        setUpdateStatusModalOpen(false);
        refechRequestBookData();
        editRequestBookData(null);
        requestBookForm.resetFields();
      }
      refechRequestBookData();
    });
  };

  const onFinishFailed = () => {
    notification.error({
      message: "Failed",
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
    });
  };
  const columns = [
    {
      title: "Book Name",
      dataIndex: "bookId",
      key: "bookId",
      align: "center",
      render: (_, book) => {
        return book.book.bookName;
      },
      sorter: (a, b) => a.book.bookName.localeCompare(b.book.bookName),
    },

    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      align: "center",
      sorter: (a, b) => a.userType.localeCompare(b.userType),
    },

    {
      title: "Requested Date",
      dataIndex: "requestedDate",
      key: "requestedDate",
      align: "center",
      sorter: (a, b) => a.requestedDate - b.requestedDate,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "center",
      render: (text, row) => (
        <Space>
          <EyeOutlined onClick={() => handleViewRequesteddBooks(row)} />

          <EditOutlined onClick={() => handleUpdateStatus(row)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="requseted-book-comtainer">
      <Table
        dataSource={requestedBookData}
        columns={columns}
        showSorterTooltip={false}
      />
      <Modal
        open={viewModal}
        title="Requsted  Books Details"
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="modal-text" span={9}>
            Book Name
          </Col>
          <Col className="modal-text" span={15}>
            {selectedRequestBook?.bookName}
          </Col>
          <Col className="modal-text" span={9}>
            Numbers Of Books
          </Col>
          <Col className="modal-text" span={15}>
            {selectedRequestBook?.numbersOfBooks}
          </Col>
          <Col className="modal-text" span={9}>
            publisher
          </Col>
          <Col className="modal-text" span={15}>
            {selectedRequestBook?.publisher}
          </Col>
          <Col className="modal-text" span={9}>
            ISBN
          </Col>
          <Col className="modal-text" span={15}>
            {selectedRequestBook?.isbn}
          </Col>
          <Col className="modal-text" span={9}>
            Book Description
          </Col>
          <Col className="modal-text" span={15}>
            {selectedRequestBook?.bookDescription}
          </Col>
        </Row>
      </Modal>
      <Modal
        open={updateStatusModalOpen}
        onCancel={cancelUpdateStatusModal}
        footer={null}
        title="Status Update"
      >
        <Form
          onFinish={onFinish}
          form={requestBookForm}
          initialValues={editRequestBookData?.id ? { remember: true } : null}
          // initialValues={false}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SelectFloatLabel label="Status" name="status">
                  <Option value="APPROVED">APPROVED</Option>
                  <Option value="REJECTED">REJECTED</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="comments"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Comments"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button
                  className="cancel-button"
                  onClick={() => setUpdateStatusModalOpen(false)}
                >
                  cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default RequestedBook;
