// import { Menu, Dropdown, Input } from "antd";
// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";

// import "antd/dist/antd.css";
// import "./header.scss";
// import {
//   BellOutlined,
//   BookOutlined,
//   SettingOutlined,
//   UserOutlined,
//   TeamOutlined,
//   InboxOutlined,
//   AppstoreOutlined,
// } from "@ant-design/icons";
// // import Sider from "antd/lib/layout/Sider";
// import author from "./../../assets/Author.svg";
// import dashboard from "../../assets/Dashboard.svg";
// import category from "../../assets/Categories.svg";
// import book from "../../assets/Books.svg";
// import Cookies from "js-cookie";
// // const icons = {
// //   dashboard: `${process.env.PUBLIC_URL}/images/dashboard-icon.svg`,
// //   books: `${process.env.PUBLIC_URL}/images/books-icon.svg`,
// //   categories: `${process.env.PUBLIC_URL}/images/categories-icon.svg`,
// //   authors: `${process.env.PUBLIC_URL}/images/authors-icon.svg`,
// //   users: `${process.env.PUBLIC_URL}/images/users-icon.svg`,
// //   inbox: `${process.env.PUBLIC_URL}/images/inbox-icon.svg`,
// // };

// export default function Header() {
//   const [select, setSelect] = useState(0);
//   function getItem(label, key, icon, children, type) {
//     return {
//       key,
//       icon,
//       children,
//       label,
//       type,
//     };
//   }

//   function handleClick(index) {
//     // localStorage.setItem("selectedItem", index);
//     setSelect(index);
//   }
//   const location = useLocation();

//   useEffect(() => {
//     const { pathname } = location;
//     if (pathname.includes("/books")) {
//       setSelect("books");
//     } else if (pathname.includes("/categories")) {
//       setSelect("categories");
//     } else if (pathname.includes("/authors")) {
//       setSelect("authors");
//     } else if (pathname.includes("/profile")) {
//       setSelect("profile");
//     } else {
//       setSelect("dashboard");
//     }
//   }, [location]);

//   const rootSubmenuKeys = ["sub1", "sub2", "sub4"];
//   const [openKeys, setOpenKeys] = useState(["sub1"]);

//   const onOpenChange = (keys) => {
//     const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

//     if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
//       setOpenKeys(keys);
//     } else {
//       setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
//     }
//   };
//   const navigate = useNavigate();
//   const staffInfo = Cookies.get("staffInfo")
//     ? JSON.parse(Cookies.get("staffInfo"))
//     : [];
//   const staffName = staffInfo?.name;
//   const menu = (
//     <Menu>
//       <Menu.Item key="0" onClick={() => navigate("/profile")}>
//         Profile
//       </Menu.Item>
//     </Menu>
//   );

//   const items = [
//     getItem(
//       <Link className="link" to="/">
//         Dashboard
//       </Link>,
//       "dashboard",
//       <img src={dashboard} alt="dashboard" className="custom-icon" />
//     ),
//     getItem(
//       <Link className="link" to="/books">
//         Books
//       </Link>,
//       "books",
//       <img src={book} alt="book" className="custom-icon" />
//     ),
//     getItem(
//       <Link className="link" to="/categories">
//         Categories
//       </Link>,
//       "categories",
//       <img src={category} alt="category" className="custom-icon" />
//     ),
//     getItem(
//       <Link className="link" to="/authors">
//         Authors
//       </Link>,
//       "authors",
//       <img src={author} alt="author" className="custom-icon" />
//     ),
//   ];

//   return (
//     <div className="app-container">
//       <header>
//         <div className="header-container">
//           <div className="topbar-logo">
//             <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiJQgMdKNw2v_VFyflfloVzUNON7PWjOJGwA&usqp=CAU" />
//             <h1 className="top-heading-vidhya">Vidhya Barathi School</h1>
//           </div>

//           <div className="search-notifications-container">
//             <div className="bell-icon">
//               <BellOutlined className="bell-icon " />
//             </div>
//             <div className="librarian-heading-container">
//               <h4 className="librarian">{staffName}</h4>
//               <Dropdown overlay={menu} placement="bottom" arrow>
//                 <img
//                   src={process.env.PUBLIC_URL + "/cardImages/card-img.png"}
//                   alt="profile-img"
//                   className="profile-img"
//                 />
//               </Dropdown>
//             </div>
//           </div>
//         </div>
//       </header>

//       <div className="left-menu-container">
//         <div className="menu-heading-container">
//           <h1 className="edpedia-heading">Edpedia</h1>
//           <p className="library-sub-heading">Library Management</p>
//         </div>

//         <Menu
//           style={{
//             backgroundColor: "#172B4D",
//           }}
//           defaultSelectedKeys={["dashboard"]}
//           selectedKeys={select}
//           onClick={({ key }) => handleClick(key)}
//           // defaultOpenKeys={["classdetails"]}
//           mode="inline"
//           className="leftmenu"
//           openKeys={openKeys}
//           onOpenChange={onOpenChange}
//           items={items}
//         />
//       </div>
//     </div>
//   );
// }

import { Menu, Dropdown, Input } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Edpedia from "../../assets/Edpedia.svg";
import "antd/dist/antd.css";
import "./header.scss";
import {
  BellOutlined,
  BookOutlined,
  SettingOutlined,
  UserOutlined,
  TeamOutlined,
  InboxOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
// import Sider from "antd/lib/layout/Sider";
import author from "./../../assets/Author.svg";
import dashboard from "../../assets/Dashboard.svg";
import category from "../../assets/Categories.svg";
import book from "../../assets/Books.svg";
import Cookies from "js-cookie";
import { useGetInstituteDetailsQuery } from "../../RTKQuery/Institute";
import Sider from "antd/lib/layout/Sider";
// const icons = {
//   dashboard: `${process.env.PUBLIC_URL}/images/dashboard-icon.svg`,
//   books: `${process.env.PUBLIC_URL}/images/books-icon.svg`,
//   categories: `${process.env.PUBLIC_URL}/images/categories-icon.svg`,
//   authors: `${process.env.PUBLIC_URL}/images/authors-icon.svg`,
//   users: `${process.env.PUBLIC_URL}/images/users-icon.svg`,
//   inbox: `${process.env.PUBLIC_URL}/images/inbox-icon.svg`,
// };

export default function Header() {
  const [select, setSelect] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const path = location?.pathname?.split("/")[1];
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  function handleClick(index) {
    // localStorage.setItem("selectedItem", index);
    setSelect(index);
  }

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("/books")) {
      setSelect("books");
    } else if (pathname.includes("/categories")) {
      setSelect("categories");
    } else if (pathname.includes("/authors")) {
      setSelect("authors");
    } else if (pathname.includes("/profile")) {
      setSelect("profile");
    } else {
      setSelect("dashboard");
    }
  }, [location]);

  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];
  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const navigate = useNavigate();
  const studentInfo = Cookies.get("studentInfo")
    ? JSON.parse(Cookies.get("studentInfo"))
    : [];

  const studentId = studentInfo?.studentId;
  const branchCode = Cookies.get("branchCode");
  const {
    data: instituteData,
    isLoading,
    isError,
  } = useGetInstituteDetailsQuery(branchCode);
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => navigate("/profile")}>
        Profile
      </Menu.Item>
    </Menu>
  );

  const items = [
    getItem(
      <Link className="link" to="/">
        Dashboard
      </Link>,
      "dashboard",
      <img src={dashboard} alt="dashboard" className="custom-icon" />
    ),
    getItem(
      <Link className="link" to="/books">
        Books
      </Link>,
      "books",
      <img src={book} alt="book" className="custom-icon" />
    ),
    getItem(
      <Link className="link" to="/categories">
        Categories
      </Link>,
      "categories",
      <img src={category} alt="category" className="custom-icon" />
    ),
    getItem(
      <Link className="link" to="/authors">
        Authors
      </Link>,
      "authors",
      <img src={author} alt="author" className="custom-icon" />
    ),
  ];
  const toggleCollapsed = () => {
    // setCollapsed(!collapsed);
  };

  return (
    <div className="app-container">
      <header>
        <div className="header-container">
          <div className="topbar-logo">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiJQgMdKNw2v_VFyflfloVzUNON7PWjOJGwA&usqp=CAU" />
            <h1 className="top-heading-vidhya">
              {instituteData?.data?.instituteName}
            </h1>
          </div>

          <div className="search-notifications-container">
            <div className="bell-icon">
              <BellOutlined className="bell-icon " />
            </div>
            <div className="librarian-heading-container">
              <h4 className="librarian">{}</h4>
              <Dropdown overlay={menu} placement="bottom" arrow>
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVd2xPPRTNuQ_26IDANOVXSqRQPwtlLxqruw&usqp=CAU"
                  alt="profile-img"
                  className="profile-img"
                />
              </Dropdown>
            </div>
          </div>
        </div>
      </header>

      {/* <div className="left-menu-container">
        <Menu
          style={{
            backgroundColor: "#172B4D",
          }}
          defaultSelectedKeys={["dashboard"]}
          selectedKeys={select}
          onClick={({ key }) => handleClick(key)}
          // defaultOpenKeys={["classdetails"]}
          mode="inline"
          className="leftmenu"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          items={items}
        />
      </div> */}
      <div className="leftmenu-block">
        <div className="edpedia-logo-container">
          <a
            href="https://www.edpedia.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Edpedia} alt="Edpedia" className="edpedia-logo" />
          </a>
        </div>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={80}
        >
          <div
            className={`${collapsed ? "collapsed" : ""}`}
            onMouseEnter={toggleCollapsed}
            onMouseLeave={toggleCollapsed}
          >
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={select}
              onClick={({ key }) => handleClick(key)}
              mode="inline"
              className="leftmenu"
              inlineCollapsed={collapsed}
              items={items}
            />
          </div>
        </Sider>
      </div>
    </div>
  );
}
