import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { Form, Select, Row, Col } from "antd";

import InputFloatLabel from "../../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
import { useFindStaffQuery } from "../../../RTKQuery/LoginedStaff";
import Cookies from "js-cookie";
const Profile = () => {
  const [staffData, setStaffData] = useState([]);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  const { data } = useFindStaffQuery(staffId);
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      setStaffData(data?.data);
      form.setFieldsValue(data?.data);
    }
  }, [data, form]);
  return (
    <main>
      <div className="profile-container">
        <div className="profile-header">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVd2xPPRTNuQ_26IDANOVXSqRQPwtlLxqruw&usqp=CAU"
            alt="profile-img"
          />
          <span style={{ marginLeft: "15px" }}>Hey , {staffData?.name}</span>
        </div>

        <div className="profile-card card">
          <Form
            className="profile-form"
            form={form}
            name="edit-profile"
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            // onValuesChange={handleFormChange}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 32 }}>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name",
                    },
                  ]}
                >
                  <InputFloatLabel label="Name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="contact"
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact!",
                    },
                    {
                      min: 10,
                      message: "please enter valid phone number",
                    },
                    {
                      max: 10,
                      message: "cannot exceed more than 10",
                    },
                  ]}
                >
                  <InputFloatLabel label="Mobile Number" type="tel" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="dateOfBirth"
                  rules={[{ required: true, message: "" }]}
                >
                  <InputFloatLabel label="Date of Birth" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="gender"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <SelectFloatLabel label="Gender">
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                    <Select.Option value="other">Other</Select.Option>
                  </SelectFloatLabel>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <InputFloatLabel label="Mail" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="experience"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="Experience" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="qualification"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="Qualification" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="deptName"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="Department Name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="designation"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="Designation" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="Country" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="City" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="State" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="designation"
                  rules={[
                    {
                      required: true,
                      message: "Please input your designation!",
                    },
                  ]}
                >
                  <InputFloatLabel label="Designation" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="qualification"
                  rules={[
                    {
                      required: true,
                      message: "please enter qualification",
                    },
                  ]}
                >
                  <InputFloatLabel label="Qualification" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="nationality"
                  rules={[
                    {
                      required: true,
                      message: "Please input your nationality",
                    },
                  ]}
                >
                  <InputFloatLabel label="Nationality" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </main>
  );
};

export default Profile;
