import React, { useEffect, useState } from "react";
import { Button, Input, Tabs, Spin } from "antd";
import { useLocation } from "react-router-dom";
import {
  useGetAllBookRequestQuery,
  useGetAllBooksQuery,
  useGetAllDueDateCompletedBookQuery,
  useGetAllRentedBookQuery,
  useGetAllSuggesionBookQuery,
} from "../../RTKQuery/Books";
import "./books.scss";
import CreateBook from "./CreateBooks/CreateBook";
import BookCard from "../../components/BookCards/BookCard";
import ReturnedBook from "./ReturnBooks/ReturnedBook";
import MissingBook from "./MissingBooks/MissingBook";
import SuggestBook from "./SuggestedBooks/SuggestBook";
import RequestedBook from "./RequestedBooks/RequestedBook";
import RentBook from "./RentedBooks/RentBook";
import AddBookIcon from "../../assets/Books.svg";
import { useParams } from "react-router-dom";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
import PendingBook from "./PendingBooks/PendingBook";
import UpcomingCollectingBook from "./UpcominngCollectionBook/UpcomingCollectingBook";

const { TabPane } = Tabs;
const { Search } = Input;
export default function Books() {
  const [isOpen, setIsOpen] = useState(false);
  const [editBookData, setEditBookData] = useState(null);
  const [bookData, setBookData] = useState([]);
  const [totalBooks, setTotalBooks] = useState(0);
  const [reloadedData, setReloadedData] = useState([]);
  const { categoryId } = useParams();
  const { authorIds } = useParams();
  const [totalRentBook, setTotalRentBook] = useState(0);
  const [totalmissingBook, setTotalMissingBook] = useState(0);
  const [totalReturnedBook, setTotalReturnedBook] = useState(0);
  const [totalSuggestedBook, setTotalSuggestedBook] = useState(0);
  const [totalPendingBook, setTotalPendingBook] = useState(0);
  const [totalNextAvailableBook, setTotalNextAvailableBook] = useState(0);
  const [totalRequestedBook, setTotalRequestedBook] = useState(0);
  const [searchedText, setSearchedText] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation();
  const {
    data,
    isLoading: isLoadingBooks,
    isError: isBooksError,
    refetch: refechBooks,
  } = useGetAllBooksQuery(
    searchedText
    //   {
    //   skip: searchedText == null,
    // }
  );
  const { data: getNextAvailableBook } = useGetAllDueDateCompletedBookQuery();
  const {
    data: getSuggesion,
    isError: isSuggetionBookError,
    isLoading: isLoadingSuggetionBook,
  } = useGetAllSuggesionBookQuery();
  const {
    data: getRentBook,
    isError: isRentedBookError,
    isLoading: isLoadingRentedBook,
  } = useGetAllRentedBookQuery();
  const {
    data: getRequestedBook,
    isError: isRequestBookError,
    isLoading: isLoadingRequestBook,
  } = useGetAllBookRequestQuery();
  const [openRentalModal, setOpenRentalModal] = useState(false);
  const tabSelected = location.state;
  useEffect(() => {
    if (tabSelected === "suggestedBooks") {
      setActiveTab("6");
    } else if (tabSelected === "requestedBook") {
      setActiveTab("3");
    } else if (tabSelected === "missingBook") {
      setActiveTab("5");
    } else if (tabSelected === "returnedBook") {
      setActiveTab("4");
    } else if (tabSelected == "rentedBook") {
      setActiveTab("2");
    } else setActiveTab("1");
  }, []);

  const loadTotalBookNumber = () => {
    if (data?.data?.books && data?.data?.books?.length !== 0) {
      setTotalBooks(data?.data?.books?.length);
    } else {
      setTotalBooks(0);
    }
  };
  useEffect(() => {
    if (data) {
      let filteredBooks = data?.data?.books;
      if (categoryId) {
        filteredBooks = filteredBooks?.filter(
          (book) => book.categoryId === parseInt(categoryId)
        );
      }

      if (authorIds) {
        const authorId = parseInt(authorIds);
        filteredBooks = filteredBooks.filter((book) =>
          book.authorIds.includes(authorId)
        );
      }

      setBookData(filteredBooks);
      loadTotalBookNumber();
    }
  }, [data, reloadedData, categoryId, authorIds]);
  useEffect(() => {
    if (
      getNextAvailableBook?.data &&
      getNextAvailableBook?.data?.nextAvailableBooks?.length !== 0
    ) {
      setTotalNextAvailableBook(getNextAvailableBook?.data?.countAvailableBook);
    } else {
      setTotalNextAvailableBook(0);
    }
  }, [getNextAvailableBook]);

  useEffect(() => {
    if (getSuggesion?.data && getSuggesion?.data?.length !== 0) {
      setTotalSuggestedBook(getSuggesion?.data?.length);
    } else {
      setTotalSuggestedBook(0);
    }
  }, [getSuggesion]);
  useEffect(() => {
    if (getRentBook?.data && getRentBook?.data?.rented?.length !== 0) {
      setTotalRentBook(getRentBook?.data?.rented.length);
    } else {
      setTotalRentBook(0);
    }
  }, [getRentBook]);

  useEffect(() => {
    if (getRequestedBook?.data && getRequestedBook?.data?.length !== 0) {
      setTotalRequestedBook(getRequestedBook?.data?.length);
    } else {
      setTotalRequestedBook(0);
    }
  }, [getRequestedBook]);

  useEffect(() => {
    if (getRentBook?.data && getRentBook?.data?.pending?.length !== 0) {
      setTotalPendingBook(getRentBook?.data?.pending?.length);
    } else {
      setTotalPendingBook(0);
    }
  }, [getRentBook]);
  useEffect(() => {
    if (getRentBook?.data && getRentBook?.data?.returned?.length !== 0) {
      setTotalReturnedBook(getRentBook?.data?.returned?.length);
    } else {
      setTotalReturnedBook(0);
    }
  }, [getRentBook]);
  useEffect(() => {
    if (getRentBook?.data && getRentBook?.data?.missing?.length !== 0) {
      setTotalMissingBook(getRentBook?.data?.missing?.length);
    } else {
      setTotalMissingBook(0);
    }
  }, [getRentBook]);

  if (
    isLoadingSuggetionBook ||
    isLoadingBooks ||
    isLoadingRentedBook ||
    isLoadingRequestBook
  ) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (
    isSuggetionBookError ||
    isBooksError ||
    isRentedBookError ||
    isRequestBookError
  ) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  const handleOpenRentalModal = () => {
    setOpenRentalModal(true);
  };

  const showModal = () => {
    setIsOpen(true);
    setEditBookData(null);
  };

  return (
    <main>
      <div className="books-main-container">
        <div className="first-item-container">
          <div className="book-total-container">
            <h2 className="books">Books</h2>
            <div className="books-count-container">
              <p className="books-count">{totalBooks}</p>
            </div>
          </div>
          <Search
            placeholder="Search Book Name"
            onChange={(event) => setSearchedText(event.target.value)}
            style={{ width: 490 }}
          />

          <Button
            type="button"
            className="add-books-button"
            onClick={() => showModal()}
          >
            <img src={AddBookIcon} alt="add books" className="button-icon " />
            <span style={{ marginLeft: 18 }}>Add Book</span>
          </Button>
          <CreateBook
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            editBookData={editBookData}
            setEditBookData={setEditBookData}
            setReloadedData={setReloadedData}
            refechBooks={refechBooks}
          />
        </div>
        <div className="second-item-container">
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab={<span className="tab">All</span>} key="1">
              <BookCard
                bookData={bookData}
                setEditBookData={setEditBookData}
                setIsOpen={setIsOpen}
                setBookData={setBookData}
                // isLoading={isLoading}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tab">
                  Books In Rent{" "}
                  <div className="tab-number-container">{totalRentBook}</div>
                </div>
              }
              key="2"
            >
              <RentBook
                setOpenRentalModal={setOpenRentalModal}
                openRentalModal={openRentalModal}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tab">
                  Requested Books
                  <div className="tab-number-container bg-green">
                    {totalRequestedBook}
                  </div>
                </div>
              }
              key="3"
            >
              <RequestedBook />
            </TabPane>
            <TabPane
              tab={
                <div className="tab">
                  Returned Books
                  <div className="tab-number-container bg-brown ">
                    {totalReturnedBook}
                  </div>
                </div>
              }
              key="4"
            >
              <ReturnedBook />
            </TabPane>
            <TabPane
              tab={
                <div className="tab">
                  Missing Books
                  <div className="tab-number-container bg-red">
                    {totalmissingBook}
                  </div>
                </div>
              }
              key="5"
            >
              <MissingBook />
            </TabPane>
            <TabPane
              tab={
                <div className="tab">
                  Suggested Books
                  <div className="tab-number-container bg-neun">
                    {totalSuggestedBook}
                  </div>
                </div>
              }
              key="6"
            >
              <SuggestBook />
            </TabPane>
            <TabPane
              tab={
                <div className="tab">
                  Pending Books
                  <div className="tab-number-container bg-orange">
                    {totalPendingBook}
                  </div>
                </div>
              }
              key="7"
            >
              <PendingBook />
            </TabPane>
            <TabPane
              tab={
                <div className="tab">
                  Next Available Books
                  <div className="tab-number-container bg-green">
                    {totalNextAvailableBook}
                  </div>
                </div>
              }
              key="8"
            >
              <UpcomingCollectingBook />
            </TabPane>
          </Tabs>
          {activeTab === "2" && (
            <div className="rentBook-form">
              <Button
                className="rent-btn"
                onClick={() => handleOpenRentalModal()}
              >
                Rent Book
              </Button>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
