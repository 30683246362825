import React, { useEffect } from "react";
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Space,
  notification,
  Input,
} from "antd";

import {
  LikeTwoTone,
  DislikeOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import "./CreateCategory.scss";
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
} from "../../../RTKQuery/Category";

const CreateCategory = ({
  isOpen,
  setIsOpen,
  categoryDetails,
  setCategoryDetails,
  refetchCategory,
}) => {
  const [categoryForm] = Form.useForm();
  const [createCategory, { error: createCategoryError }] =
    useCreateCategoryMutation();
  const [editCategory, { error: editCategoryError }] =
    useEditCategoryMutation();

  useEffect(() => {
    if (categoryDetails?.id) {
      categoryForm.setFieldsValue({
        categoryNames: categoryDetails?.categoryName,
      });
    } else {
      categoryForm.resetFields();
    }
  }, [categoryDetails, categoryForm]);

  const onFinish = (values) => {
    const id = categoryDetails?.id;
    const payload = {
      categoryNames: [values.categoryNames],
    };

    if (!id) {
      createCategory({ payload: payload }).then((res) => {
        let errorMessage = "";
        if (res.error && res.error.status === 500) {
          errorMessage = res.error.data.message;
        }
        if (createCategoryError) {
          errorMessage = createCategoryError.data.message;
        }
        if (errorMessage) {
          notification.error({
            message: "Failed",
            placement: "bottomRight",
            description: `${createCategoryError?.data?.message} `,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        } else {
          setIsOpen(false);

          notification.success({
            message: `$Category Created Successfully .`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
          setCategoryDetails(null);
          categoryForm.resetFields();
          refetchCategory();
        }
      });
    } else {
      editCategory({ id: id, payload: payload }).then((res) => {
        let errorMessage = "";
        if (res.error && res.error.status === 500) {
          errorMessage = res.error.data.message;
        }
        if (editCategoryError) {
          errorMessage = editCategoryError.data.message;
        }
        if (errorMessage) {
          notification.error({
            message: "Failed",
            placement: "bottomRight",
            description: `${editCategoryError?.data?.message} `,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        } else {
          notification.success({
            message: `${categoryDetails?.categoryName} Updated Successfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });

          setCategoryDetails(null);
          setIsOpen(false);
          categoryForm.resetFields();
          refetchCategory();
        }
      });
    }
    refetchCategory();
  };
  const handleCancel = () => {
    setIsOpen(false);
    setCategoryDetails(null);
    categoryForm.resetFields();
  };
  const onFinishFailed = () => {
    notification.error({
      message: "Failed",
      placement: "bottomRight",
      icon: <DislikeOutlined style={{ color: "red" }} />,
    });
  };
  return (
    <>
      <Modal
        title={
          categoryDetails && Object.keys(categoryDetails).length > 0
            ? "Edit Category"
            : "Create Category"
        }
        className="category-modal"
        visible={isOpen}
        width={500}
        onCancel={handleCancel}
        centered
        footer={null}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Form
          name="CategoryForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={categoryForm}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="categoryNames"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  className="float-inputs"
                  Placeholder=" Category Name"
                  multiple
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="cancel-button" onClick={handleCancel}>
                  cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCategory;
